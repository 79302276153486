import React from "react";
import { env } from '../../../../configs/EnvironmentConfig'
import InternalIFrame from '../../../../components/layout-components/InternalIFrame';

const activatedinsightsSupport = `https://activatedinsights.my.site.com/help/s/`;

export const MyClients = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/interviewees?type=client&keep_printable=true`}/></div>
	)
}

export const MyCaregivers = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/interviewees?type=caregiver&keep_printable=true`}/></div>
	)
}

export const ReviewIntegrations = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/account/rep_mgmt?keep_printable=true`}/></div>
	)
}

export const InformLetters = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/reports/view/inform_letters/3?keep_printable=true`}/></div>
	)
}

export const HelpCenter = () => {
	redirectUrl(activatedinsightsSupport);
	return (
		<div>Support page will be opened on a separete tab</div>
	)
}

export const Support = () => {
	redirectUrl(activatedinsightsSupport);
	return (
		<div>Support page will be opened on a separete tab</div>
	)
}

export const MonthlyReports = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/reports/monthly?keep_printable=true`}/></div>
	)
}

export const CustomPDF = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/reports/custom?keep_printable=true`}/></div>
	)
}

export const NetPromoterDiscovery = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/reports/view/net_promoter_discovery/3?keep_printable=true`}/></div>
	)
}

export const MyLeadInbox = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/bohc/optin?keep_printable=true`}/></div>
	)
}

export const MyServiceArea = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/bohc/listing/2?keep_printable=true`}/></div>
	)
}

export const MyListings = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/bohc/listing/1?keep_printable=true`}/></div>
	)
}

export const MyAwards = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/bohc/listing/3?keep_printable=true`}/></div>
	)
}

export const AwardCriteria = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/bohc/awardcriteria?keep_printable=true`}/></div>
	)
}

export const Notifications = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/account/notifications?keep_printable=true`}/></div>
	)
}

export const YourLogo = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/account/logomgmt?keep_printable=true`}/></div>
	)
}

export const BillingDefault = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/account/billing?keep_printable=true`}/></div>
	)
}

// Corporate Section
export const VantageLocations = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/locations_corp?keep_printable=true`}/></div>
	)
}

export const ReportReference = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/reports_corp/reference?keep_printable=true`}/></div>
	)
}

export const SatisfactionManagement = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/reports_corp/satisfaction_management?keep_printable=true`}/></div>
	)
}

export const Comparison = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/reports_corp/comparison?keep_printable=true`}/></div>
	)
}

export const Trends = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/reports_corp/trends?keep_printable=true`}/></div>
	)
}

export const BillingCorp = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/account_corp/billing?keep_printable=true`}/></div>
	)
}

export const ContactInfoAndUsersCorp = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/account_corp?keep_printable=true`}/></div>
	)
}

export const GoToVantage = () => {
	return (
		<div><a href={`${env.VANTAGE_URI}?keep_printable=false`} target="_blank">this link</a></div>		
	)
}

export const FileUpload = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/fileupload?user_type=provider&keep_printable=true`}/></div>
	)
}

export const FileUploadCorp = () => {
	return (
		<div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/fileupload?user_type=corp&keep_printable=true`}/></div>
	)
}

export const ActivatedInsightsGate = (props) => {
	const { history } = props;	
	window.open(env.PUBLIC_ACTIVATED_INSIGHTS_APP_HOST);
	history.push('/');
	return (
		<div></div>
	)
}

export const PinnacleRetain = (props) => {
	const { history } = props;
	window.open(env.PUBLIC_PINNACLE_RETAIN_APP_HOST);
	history.push('/');
	return (
		<div></div>
	)
}

export const PinnacleGate = (props) => {
	const { history } = props;	
	window.open(env.PUBLIC_PINNACLE_QI_APP_HOST);
	history.push('/');
	return (
		<div></div>
	)
}

export const redirectUrl = (url) => {  
	window.open(url);
  }; 