import React from "react";
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from '../../constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent'
import Title from '../../components/layout-components/Title';
import WidgetAction from '../../components/shared-components/WidgetAction';

const { Sider } = Layout;

export const SideNav = ({navColor, navTitle, navCollapsed, sideNavTheme, routeInfo, hideGroupTitle, localization = true }) => {
	const props = { sideNavTheme, routeInfo , hideGroupTitle, localization}
	let hexColor = navColor ? navColor : "#3ca292";
	return (
		<div>
			<Sider 
				className={`cip-side-nav side-nav side-nav--alt ${sideNavTheme === SIDE_NAV_DARK? 'side-nav-dark' : ''}`} 
				width={SIDE_NAV_WIDTH} 
				collapsed={navCollapsed}
			>

				<Scrollbars autoHide>
					<Title title={navTitle} color={hexColor} isCollapsed={navCollapsed}/>
					
					<MenuContent 
						type={NAV_TYPE_SIDE} 
						{...props}
					/>

					<WidgetAction isCollapsed={navCollapsed}/>
				</Scrollbars>
			</Sider>
		</div>
	)
}

const mapStateToProps = ({ theme }) => {
	const { navCollapsed, sideNavTheme } =  theme;
	return { navCollapsed, sideNavTheme }
};

export default connect(mapStateToProps)(SideNav);
