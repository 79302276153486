import { APP_PREFIX_PATH } from '../../AppConfig';
import { env } from "../../EnvironmentConfig";
import cahpsAllowedAgencies from '../../../assets/data/CAHPSAllowedAgencies.data.json'

export const getLocationReportNestedSubmenu = (companyId, userName, groupId, companyGroupIds) => {

	const dashBoardNavTree = [
		{
			key: 'sidenav.experience.reports.monthlyReports',
			path: `${APP_PREFIX_PATH}/experience/monthly-reports`,
			title: 'sidenav.experience.reports.monthlyReports',
			icon: '',
			iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		},
		{
			key: 'sidenav.experience.reports.customPdf',
			path: `${APP_PREFIX_PATH}/experience/custom-pdf`,
			title: 'sidenav.experience.reports.customPdf',
			icon: '',
			iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		},
		{
			key: 'sidenav.experience.reports.netPromoterDiscovery',
			path: `${APP_PREFIX_PATH}/experience/net-promoter-discovery`,
			title: 'sidenav.experience.reports.netPromoterDiscovery',
			icon: '',
			iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		}
	]


	return dashBoardNavTree;
	
}

export const isToAllowCAHPSReport = (companyId) => {	
	if(companyId){		
		const permissions = cahpsAllowedAgencies[0];		
		console.log(permissions);
		const agencyNumber = companyId ?? 0;		
		console.log((permissions?.allowedAgencies?.includes(agencyNumber)))
		return (permissions?.allowedAgencies?.includes(agencyNumber)) ? true : false;
	}
	
	return false;

}
