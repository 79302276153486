import axios from 'axios';
import { env } from '../../../configs/EnvironmentConfig'

const adminIdEndpoint = `${env.DECISIONS_API_ENDPOINT_URL}/Primary/restapi/Flow/3a302214-735b-11ee-9281-326dddd3e106`;


const requestBody = {
  "userid": env.GUESS_ENDPOINT_USER_ID,
  "password": env.GUESS_PWD,
  "outputtype": "RawJson",
};

let _userAccount = [];

export const getDncListForCompanyGroup = async(token, companyId, startingDate, endingDate, whoCalledMe) => {  
  if (token && companyId > 0) {

    requestBody["JWT"] = token;
    requestBody["CompanyId"] = companyId;
    requestBody["StartDate"] = startingDate;
    requestBody["EndDate"] = endingDate;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: 'follow'
    };
    
    try{

      const results = await fetch(adminIdEndpoint, requestOptions).catch((error) => {
        console.log(`Error Retrieving accountInfo in getDncListForCompanyGroup: from ${whoCalledMe}`);
        console.error(error)
      });    
      const apiResult = await results.json();
      const parsedResult = apiResult["DNCInterviewees"];
      return parsedResult.length > 0 ? parsedResult : _userAccount;

    }catch(err){
      return [];
    }
  }

  return [];
}

const CallContactService = {
  getDncListForCompanyGroup,
};

export default CallContactService;
