
import { env } from '../../../configs/EnvironmentConfig';
import DateConfigurations from '../../../shared-logic/DateConfigurations';

// Filters
const dwDateRangeOptions = `https://warehouse.homecarepulse.com/DateRangeType?select=DateRangeTypeId&IsIncludedInMetrics=eq.true&order=CompanyGroupId.asc,IsDashboardMetricsDefault.desc`;
const dwContactRelation = `https://warehouse.homecarepulse.com/ContactRelation?select=ContactRelationId&ContactRelationTypeId=eq.Professional`;

//CompanySync
const dwNpsAndOsatOverallCompanySyncData = `https://warehouse.homecarepulse.com/Report_Company_Metric?select=CompanyId,CompanyName,DateRangeDisplayName,CompanyNpsScore,CompanyNpsPassiveCount,CompanyNpsPromoterCount,CompanyNpsDetractorCount,CompanyNpsTotalCount,CompanyNpsPromoterPercent,CompanyNpsPassivePercent,CompanyNpsDetractorPercent,CompanyOverallSatisfactionScore,CompanyOverallSatisfactionTotalCount&`;
const dwNpsAndOsatOverallCompanySyncGoals = `https://warehouse.homecarepulse.com/CompanyGoalMetric?select=CompanyId,ContactRelationId,NpsGoalMinimum,NpsGoalStandard,OsatGoalMinimum,OsatGoalStandard&`;
const dwParentCompanyGroupData = `https://warehouse.homecarepulse.com/Report_Company_CompanyGroup?select=CompanyGroupName,CompanyGroupId,CompanyId_Parent&`;
const dwOsatResultsByTagForCompanySync = `https://warehouse.homecarepulse.com/Report_Company_TagMetric?select=DateRangeDisplayName,DateStartedAt,DateEndedBefore,CompanyName,CompanyId,TagId,IsOverallSatisfactionTag,CompanyTagScoreAvg,CompanyTagCount&`;
const dwSurveyResultsByCompanySync = `https://warehouse.homecarepulse.com/Report_SurveyResponseValueSingle_Reportable?select=SurveyResponseId,SurveyTitle,CompanyId,CompanyName,ContactRelationId,FullName,FirstName,LastName,StartedAt,Comment,ValueNumeric,TagId,NpsScore,NpsLevelId,OverallSatisfactionScore,Language_QuestionText,ValueBoolean,HasNpsTag,HasOverallSatisfactionTag&or=(HasNpsTag.eq.true,HasOverallSatisfactionTag.eq.true)&`
const dwNpsCompanyGroupSyncForCompanySync = `https://warehouse.homecarepulse.com/Report_CompanyGroup_Metric?select=CompanyGroupId,CompanyGroupName,DateRangeDisplayName,CompanyGroupNpsScore,CompanyGroupOverallSatisfactionScore,CompanyGroupOverallSatisfactionTotalCount,CompanyGroupNpsPromoterCount,CompanyGroupNpsPassiveCount,CompanyGroupNpsDetractorCount,CompanyGroupNpsTotalCount,CompanyGroupNpsPromoterPercent,CompanyGroupNpsPassivePercent,CompanyGroupNpsDetractorPercent&`;
const dwNpsCompanyGroupSyncForCompanySyncAliases = `https://warehouse.homecarepulse.com/Report_CompanyGroup_Metric?select=CompanyId:CompanyGroupId,CompanyName:CompanyGroupName,DateRangeDisplayName,CompanyNpsScore:CompanyGroupNpsScore,CompanyOverallSatisfactionScore:CompanyGroupOverallSatisfactionScore,CompanyOverallSatisfactionTotalCount:CompanyGroupOverallSatisfactionTotalCount,CompanyNpsPromoterCount:CompanyGroupNpsPromoterCount,CompanyNpsPassiveCount:CompanyGroupNpsPassiveCount,CompanyNpsDetractorCount:CompanyGroupNpsDetractorCount,CompanyNpsTotalCount:CompanyGroupNpsTotalCount,CompanyNpsPromoterPercent:CompanyGroupNpsPromoterPercent,CompanyNpsPassivePercent:CompanyGroupNpsPassivePercent,CompanyNpsDetractorPercent:CompanyGroupNpsDetractorPercent&`;
const dwTagResultsForCompanyGroup = `https://warehouse.homecarepulse.com/Report_CompanyGroup_TagMetric?select=CompanyGroupName,CompanyGroupId,DateRangeDisplayName,TagId,CompanyGroupTagScoreAvg,CompanyGroupTagCount,IndustryTagScoreAvg,IsOverallSatisfactionTag,CompanyGroupId&`;


//CompanyGroupSync
const dwCompanyGroupSyncNpsAndOsat = `https://warehouse.homecarepulse.com/Report_CompanyGroupSync_Metric?select=DateRangeDisplayName,CompanyGroupName_Source,CompanyGroupNpsScore,CompanyGroupOverallSatisfactionScore,CompanyGroupOverallSatisfactionTotalCount,CompanyGroupNpsPromoterCount,CompanyGroupNpsPassiveCount,CompanyGroupNpsDetractorCount,CompanyGroupNpsTotalCount,CompanyGroupNpsPromoterPercent,CompanyGroupNpsPassivePercent,CompanyGroupNpsDetractorPercent&`;
const dwIndustryNpsAndOsat = `https://warehouse.homecarepulse.com/Report_CompanyGroupSync_Metric?select=DateRangeDisplayName,IndustryNpsScore,IndustryOverallSatisfactionScore,IndustryNpsScore,IndustryNpsPromoterPercentage,IndustryNpsPassivePercent,IndustryNpsDetractorPercent&`;
const dwOsatBrokendownDataByCompanyGroup = `https://warehouse.homecarepulse.com/Report_Company_TagMetric?select=DateRangeDisplayName,DateStartedAt,DateEndedBefore,CompanyName,TagId,IsOverallSatisfactionTag,CompanyTagScoreAvg,CompanyTagCount&`;
const dwOsatResultsByTagForCompanyGroupSync = `https://warehouse.homecarepulse.com/Report_CompanyGroupSync_TagMetric?select=CompanyGroupName_Source,DateRangeDisplayName,TagId,CompanyGroupTagScoreAvg,CompanyGroupTagCount,IsOverallSatisfactionTag&CompanyGroupId_Target=eq.c47b82c2-35c1-11eb-9010-4b3000001618&`;
const dwOsatOverallResultsByIndustryForCompanyGroupSync = `https://warehouse.homecarepulse.com/Report_CompanyGroupSync_Metric?select=DateRangeDisplayName,CompanyGroupId_Source,CompanyGroupName_Source,CompanyGroupOverallSatisfactionScore,IndustryOverallSatisfactionScore,CompanyGroupOverallSatisfactionTotalCount&CompanyGroupId_Target=eq.c47b82c2-35c1-11eb-9010-4b3000001618&`;                                                          

//CompanyGroup
const dwNpsAndOsatCompanyGroupGoals = `https://warehouse.homecarepulse.com/CompanyGroupGoalMetric?select=CompanyId:CompanyGroupId,ContactRelationId,NpsGoalMinimum,NpsGoalStandard,OsatGoalMinimum,OsatGoalStandard&`;
const dwCompanyGroupUudi = `https://warehouse.homecarepulse.com/CompanyGroup?select=CompanyGroupName,CompanyGroupId&`;
const dwAgenciesBycompanyGroup = `https://warehouse.homecarepulse.com/Report_Company_CompanyGroup?select=CompanyId,CompanyName&`;
const dwNpsAndOsatOverallCompanyGroupData = `https://warehouse.homecarepulse.com/Report_CompanyGroupSync_Metric?select=CompanyGroupId_Source,DateRangeDisplayName,CompanyGroupName_Source,CompanyGroupNpsScore,CompanyGroupOverallSatisfactionScore,CompanyGroupOverallSatisfactionTotalCount,CompanyGroupNpsPromoterCount,CompanyGroupNpsPassiveCount,CompanyGroupNpsDetractorCount,CompanyGroupNpsTotalCount,CompanyGroupNpsPromoterPercent,CompanyGroupNpsPassivePercent,CompanyGroupNpsDetractorPercent&`;
const dwOsatResultsByTagForCompanyGroup = `https://warehouse.homecarepulse.com/Report_CompanyGroupSync_TagMetric?select=CompanyGroupName_Source,DateRangeDisplayName,TagId,CompanyGroupTagScoreAvg,CompanyGroupTagCount,IsOverallSatisfactionTag&CompanyGroupId_Target=eq.c47b82c2-35c1-11eb-9010-4b3000001618&`;
const dwNpsAndOsatByAgencyForCompanyGroup = `https://warehouse.homecarepulse.com/Report_Company_Metric?select=CompanyId,CompanyName,DateRangeDisplayName,CompanyNpsScore,CompanyNpsPassiveCount,CompanyNpsPromoterCount,CompanyNpsDetractorCount,CompanyNpsTotalCount,CompanyNpsPromoterPercent,CompanyNpsPassivePercent,CompanyNpsDetractorPercent,CompanyOverallSatisfactionScore,CompanyOverallSatisfactionTotalCount&`;
const dwOsatResultsByTagForCompanies = `https://warehouse.homecarepulse.com/Report_Company_TagMetric?select=DateRangeDisplayName,DateStartedAt,DateEndedBefore,CompanyName,CompanyId,TagId,IsOverallSatisfactionTag,CompanyTagScoreAvg,CompanyTagCount&`

//Additional
const dwAllQuestions = `https://warehouse.homecarepulse.com/Report_SurveyResponseValueSingle_Reportable?select=SurveyResponseId,CompanyId,CompanyName,SurveyTitle,ContactRelationId,FullName,FirstName,LastName,StartedAt,Comment,ValueNumeric,DisplayOrder,TagId,NpsScore,NpsLevelId,OverallSatisfactionScore,Language_QuestionText,ValueBoolean,HasNpsTag,HasOverallSatisfactionTag&`;
const dwAllQuestionsWithCompanyGroupWithDetails = `https://warehouse.homecarepulse.com/Report_SurveyResponseValueSingle_CompanyGroup?select=SurveyResponseId,CompanyId,CompanyName,SurveyTitle,ContactRelationId,FullName,FirstName,LastName,StartedAt,Comment,ValueNumeric,DisplayOrder,TagId,NpsScore,NpsLevelId,OverallSatisfactionScore,Language_QuestionText,ValueBoolean,HasNpsTag,HasOverallSatisfactionTag,CompanyGroupName,CompanyId_Parent,CompanyId_Parent_Parent,CompanyGroupName_Parent&`;
const dwAllQuestionsWithCompanyGroup = `https://warehouse.homecarepulse.com/Report_SurveyResponseValueSingle_CompanyGroup?select=CompanyId,CompanyName,CompanyGroupName,CompanyId_Parent,CompanyId_Parent_Parent,CompanyGroupName_Parent&`;
const dwBayadaExclusiveDateRanges = `https://warehouse.homecarepulse.com/DateRange?select=DateStartedAt,DateEndedBefore,DateRangeDisplayName`;
const dwReportSampleContactTermDetails = `https://warehouse.homecarepulse.com/Report_Sample_ContactTerm_Details?select=*`;
const dwCexReport = `https://warehouse.homecarepulse.com/Report_AgpSurvey_Company_CompanyGroup_CompanyGroupSync?select=*`;

// Post
const dwNpsAndOsatCompanyGroupGoalsPOST = `https://warehouse.homecarepulse.com/CompanyGroupGoalMetric`;
const dwNpsAndOsatCompanySyncGoalsPOST = `https://warehouse.homecarepulse.com/CompanyGoalMetric`;


// Vantage
const decisionsEndPoint= `${env.DECISIONS_API_ENDPOINT_URL}/Primary/restapi/Flow/`;
const requestBody = {
  "userid": env.GUESS_ENDPOINT_USER_ID,
  "password": env.GUESS_PWD,
  "outputtype": "RawJson",
};

// ${env.DATAWAREHOUSE_API_ENDPOINT}
// const defaultStartDate = '2023-01-02';
// const defaultEndDate = '2023-07-02';
const defaultEndDate = DateConfigurations.getFirstDayOfNextMonth();
const defaultStartDate = DateConfigurations.subtract6Months();


export const getBayadaExclusiveDateRanges = async(token, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getBayadaExclusiveDateRanges")
    console.log("getBayadaExclusiveDateRanges Token Info");
  }

  if (token) {  

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Agp");
    myHeaders.append("Content-Profile", "Agp");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getBayadaExclusiveDateRanges requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // default to quarter bayada
      const dateRangeType ='Quarter_Bayada';
      const endpointWithFilters = `${dwBayadaExclusiveDateRanges}&DateRangeTypeId=eq.${dateRangeType}&order=DateStartedAt.desc`
            
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getBayadaExclusiveDateRanges: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiResults = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getBayadaExclusiveDateRanges");
          console.log(results);
          console.log("apiResults");
          console.log(apiResults);
        }

        return apiResults
      }

    }catch(err){
      console.error("ERROR processing getBayadaExclusiveDateRanges");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCompanyGroupSyncResultsTagIds = async(token, startDate, endDate, dateType, contactId, companyVantageId, groupId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getCompanyGroupSyncResultsTagIds")
    console.log("getCompanyGroupSyncResultsTagIds Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  if (token && companyVantageId) {  

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanyGroupSyncResultsTagIds requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      const companyGroupIdSource = 'c47b82c2-35c1-11eb-9010-4b3000001618';
      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';

      const endpointWithFilters = `${dwTagResultsForCompanyGroup}CompanyGroupId=eq.${companyGroupIdSource}&DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=DateStartedAt.desc`
            
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanyGroupSyncResultsTagIds: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanyGroupSyncResultsTagIds");
          console.log(results);
          console.log("apiOsatResult");
          console.log(apiOsatResult);
        }

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getCompanyGroupSyncResultsTagIds");
      return apiResult;
    }

    return apiResult;
  }
}

export const getAllQuestionsForCompaniesUnderGivenCompanyGroup = async(token, startDate, endDate, dateType, contactId, companyVantageId, groupId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.warn("Inside getAllQuestionsForCompaniesUnderGivenCompanyGroup")
    console.warn("getAllQuestionsForCompaniesUnderGivenCompanyGroup Token Info");
    // console.log(token);
    console.log(startDate);
    console.log(endDate);
    console.log(dateType);
    console.log(contactId);
    console.log(companyVantageId);
  }

  
  if (token && companyVantageId) {  

    const agenciesUnderCompanyGroup = await AccountExperienceService.getAgenciesAssociatedByCompanyGroup(token, companyVantageId, "getAllQuestionsForCompaniesUnderGivenCompanyGroup");
    let filterCompanyIds = agenciesUnderCompanyGroup?.map(a => a.CompanyId);
    let allAgencyIds = filterCompanyIds?.join(", ");

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpSurvey");
    myHeaders.append("Content-Profile", "AgpSurvey");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getAllQuestionsForCompaniesUnderGivenCompanyGroup requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      console.warn("ALL INTERVIEWS REQUEST") 
      console.warn(dateStartedAt);
      console.warn(dateEndedBefore);
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwAllQuestions}ContactRelationId=eq.${contactType}&CompanyId=in.(${allAgencyIds})&order=StartedAt.asc,DisplayOrder.asc,HasNpsTag.desc,TagId.desc&StartedAt=gte.${dateStartedAt}&StartedAt=lt.${dateEndedBefore}`;
      
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getAllQuestionsForCompaniesUnderGivenCompanyGroup: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("Result of list of companies");
          console.log(allAgencyIds);
          console.log("resultsFor->getAllQuestionsForCompaniesUnderGivenCompanyGroup");
          console.log(results);
          console.warn("results---->>>getAllQuestionsForCompaniesUnderGivenCompanyGroup");
          console.log(apiOsatResult);
        }
        

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getAllQuestionsForCompaniesUnderGivenCompanyGroup");
      return apiResult;
    }

    return apiResult;
  }
}

export const getAllQuestionsForCompaniesUnderGivenCompanyGroupWithDivisionsAndCorps = async(token, startDate, endDate, dateType, contactId, companyVantageId, groupId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.warn("Inside getAllQuestionsForCompaniesUnderGivenCompanyGroupWithDivisionsAndCorps")
    console.warn("getAllQuestionsForCompaniesUnderGivenCompanyGroupWithDivisionsAndCorps Token Info");
    // console.log(token);
    console.log(startDate);
    console.log(endDate);
    console.log(dateType);
    console.log(contactId);
    console.log(companyVantageId);
  }

  
  if (token && companyVantageId) {  

    const agenciesUnderCompanyGroup = await AccountExperienceService.getAgenciesAssociatedByCompanyGroup(token, companyVantageId, "getAllQuestionsForCompaniesUnderGivenCompanyGroup");
    let filterCompanyIds = agenciesUnderCompanyGroup?.map(a => a.CompanyId);
    let allAgencyIds = filterCompanyIds?.join(", ");

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpSurvey");
    myHeaders.append("Content-Profile", "AgpSurvey");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getAllQuestionsForCompaniesUnderGivenCompanyGroupWithDivisionsAndCorps requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      // default to quarter bayada
      const companyGroupTypeId = 'CustomUnit';
      const companyGroupName = 'division'
      const divisionGroup = 'c47b82c2-35c1-11eb-9010-4b3000001618';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      console.warn("ALL INTERVIEWS REQUEST"); 
      console.warn(dateStartedAt);
      console.warn(dateEndedBefore);
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwAllQuestionsWithCompanyGroup}CompanyGroupName=ilike.%${companyGroupName}%&CompanyGroupTypeId=eq.${companyGroupTypeId}&ContactRelationId=eq.${contactType}&CompanyId=in.(${allAgencyIds})&order=StartedAt.asc,DisplayOrder.asc,HasNpsTag.desc,TagId.desc&StartedAt=gte.${dateStartedAt}&StartedAt=lt.${dateEndedBefore}`;
      // const endpointWithFilters = `${dwAllQuestionsWithCompanyGroup}CompanyGroupName=ilike.%${companyGroupName}%&CompanyGroupTypeId=eq.${companyGroupTypeId}&ContactRelationId=eq.${contactType}&CompanyGroupId_Parent=eq.${divisionGroup}&order=StartedAt.asc,DisplayOrder.asc,HasNpsTag.desc,TagId.desc&StartedAt=gte.${dateStartedAt}&StartedAt=lt.${dateEndedBefore}`;
      
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getAllQuestionsForCompaniesUnderGivenCompanyGroup: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("Result of list of companies");
          console.log(allAgencyIds);
          console.log("resultsFor->getAllQuestionsForCompaniesUnderGivenCompanyGroupWithDivisionsAndCorps");
          console.log(results);
          console.warn("results---->>>getAllQuestionsForCompaniesUnderGivenCompanyGroupWithDivisionsAndCorps");
          console.log(apiOsatResult);
        }
        

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getAllQuestionsForCompaniesUnderGivenCompanyGroupWithDivisionsAndCorps", err);
      return apiResult;
    }

    return apiResult;
  }
}

export const getAllQuestionsForGivenCompany = async(token, startDate, endDate, dateType, contactId, companyVantageId, groupId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getAllQuestionsForGivenCompany")
    console.log("getAllQuestionsForGivenCompany Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    console.log(contactId);
    console.log(companyVantageId);
  }

  
  if (token && companyVantageId) {  

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpSurvey");
    myHeaders.append("Content-Profile", "AgpSurvey");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getAllQuestionsForGivenCompany requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwAllQuestions}ContactRelationId=eq.${contactType}&CompanyId=eq.${companyVantageId}&order=StartedAt.asc,DisplayOrder.asc,HasNpsTag.desc,TagId.desc&StartedAt=gte.${dateStartedAt}&StartedAt=lt.${dateEndedBefore}`;
      
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getAllQuestionsForGivenCompany: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getAllQuestionsForGivenCompany");
          console.log(results);
          console.warn("results---->>>getAllQuestionsForGivenCompany");
          console.log(apiOsatResult);
        }
        

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getAllQuestionsForGivenCompany");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCompanySyncNpsAndOsatData = async(token, startDate, endDate, dateType, contactId, companyVantageId, groupId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("^^^^Inside getCompanySyncNpsAndOsatData")
    console.log("getCompanySyncNpsAndOsatData Token Info");
    console.log(companyVantageId);
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  if (token && companyVantageId) { 

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanySyncNpsAndOsatData requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      // default to quarter bayada      
      let  dateRangeType = (dateType && dateType === 'Quarter') ? 'Quarter_Bayada' : dateType; 
      dateRangeType = dateRangeType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwNpsAndOsatOverallCompanySyncData}DateRangeTypeId=eq.${dateRangeType}&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&IndustryId=eq.HomeCare&ContactRelationId=eq.${contactType}&CompanyId=in.(${companyVantageId})&order=CompanyId.desc,DateStartedAt.asc`;

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanySyncNpsAndOsatData: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiCompanyNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanySyncNpsAndOsatData");
          console.log(results);
          console.log("apiCompanyNpsAndOsatResult");
          console.log(apiCompanyNpsAndOsatResult);
        }
        console.log("==========END");
        return apiCompanyNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing getCompanySyncNpsAndOsatData");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCompanySyncNpsAndOsatGoals = async(token, contactId, companyVantageId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("^^^^Inside getCompanySyncNpsAndOsatGoals")
    console.log("getCompanySyncNpsAndOsatGoals Token Info");
    console.log(companyVantageId);
  }

  if (token && companyVantageId) { 

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanySyncNpsAndOsatGoals requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwNpsAndOsatOverallCompanySyncGoals}ContactRelationId=eq.${contactType}&CompanyId=in.(${companyVantageId})&order=CompanyId.desc`;

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanySyncNpsAndOsatGoals: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiCompanyNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanySyncNpsAndOsatGoals");
          console.log(results);
          console.log("apiCompanyNpsAndOsatResult");
          console.log(apiCompanyNpsAndOsatResult);
        }
        console.log("==========END");
        return apiCompanyNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing getCompanySyncNpsAndOsatGoals");
      return apiResult;
    }

    return apiResult;
  }
}

export const postCompanySyncNpsAndOsatGoals = async(token, goals, companyVantageId, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("^^^^Inside postCompanySyncNpsAndOsatGoals")
    console.log("postCompanySyncNpsAndOsatGoals Token Info");
    console.log(companyVantageId);
  }

  if (token && companyVantageId > 0) { 

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    goals.ContactRelationId = contactId ?? 'Client';

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
         data: goals
      }),
      redirect: 'follow',
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("postCompanySyncNpsAndOsatGoals requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   

      const endpointWithFilters = `${dwNpsAndOsatCompanySyncGoalsPOST}`;

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in postCompanySyncNpsAndOsatGoals: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 201){
        const apiCompanyNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->postCompanySyncNpsAndOsatGoals");
          console.log(results);
          console.log("postCompanySyncNpsAndOsatGoals");
          console.log(apiCompanyNpsAndOsatResult);
        }
        console.log("==========END");
        return apiCompanyNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing postCompanySyncNpsAndOsatGoals");
      return apiResult;
    }

    return apiResult;
  }
}

export const getOverallOsatTagResultsByCompaniesUnderCompanyGroup = async(token, startDate, endDate, dateType, contactId, companyVantageId, groupId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getOverallOsatTagResultsByCompaniesUnderCompanyGroup")
    console.log("getOverallOsatTagResultsByCompaniesUnderCompanyGroup Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
  }

  if (token && companyVantageId) {  

    const agenciesUnderCompanyGroup = await AccountExperienceService.getAgenciesAssociatedByCompanyGroup(token, companyVantageId, "getOverallOsatTagResultsByCompaniesUnderCompanyGroup");
    let filterCompanyIds = agenciesUnderCompanyGroup?.map(a => a.CompanyId);
    let allAgencyIds = filterCompanyIds?.join(", ");

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getOverallOsatTagResultsByCompaniesUnderCompanyGroup requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   

      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwOsatResultsByTagForCompanies}DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&CompanyId=in.(${allAgencyIds})&order=DateStartedAt.desc,TagId.asc`;
          
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getOverallOsatTagResultsByCompaniesUnderCompanyGroup: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getOverallOsatTagResultsByCompaniesUnderCompanyGroup");
          console.log(results);
          console.log("apiOsatResult");
          console.log(apiOsatResult);
        }

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getOverallOsatTagResultsByCompaniesUnderCompanyGroup");
      return apiResult;
    }

    return apiResult;
  }
}

export const getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup = async(token, startDate, endDate, dateType, contactId, companyVantageId, groupId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup")
    console.log("getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
  }

  if (token && companyVantageId) {  

    const agenciesUnderCompanyGroup = await AccountExperienceService.getAgenciesAssociatedByCompanyGroup(token, companyVantageId, "getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup");
    let filterCompanyIds = agenciesUnderCompanyGroup?.map(a => a.CompanyId);
    let allAgencyIds = filterCompanyIds?.join(", ");

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   

      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwNpsAndOsatByAgencyForCompanyGroup}DateRangeTypeId=eq.${dateRangeType}&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&IndustryId=eq.HomeCare&ContactRelationId=eq.${contactType}&CompanyId=in.(${allAgencyIds})&order=CompanyName.asc,DateStartedAt.asc`;// CompanyId.desc
            
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup");
          console.log(results);
          console.log("getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup");
          console.log(apiOsatResult);
        }

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCompanyResultsByTagIdForCompanySync = async(token, startDate, endDate, dateType, contactId, companyVantageId, groupId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getCompanyResultsByTagIdForCompanySync")
    console.log("getCompanyResultsByTagIdForCompanySync Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  
  if (token && companyVantageId) {  

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanyResultsByTagIdForCompanySync requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwOsatResultsByTagForCompanySync}CompanyId=in.(${companyVantageId})&DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=DateStartedAt.asc,TagId.asc`;
            
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanyResultsByTagIdForCompanySync: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanyResultsByTagIdForCompanySync");
          console.log(results);
          console.log("apiOsatResult");
          console.log(apiOsatResult);
        }

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getCompanyResultsByTagIdForCompanySync");
      return apiResult;
    }

    return apiResult;
  }
}

export const getTagIdResultsForCompanyGroup = async(token, startDate, endDate, dateType, contactId, companyVantageId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getTagIdResultsForCompanyGroup")
    console.log("getTagIdResultsForCompanyGroup Token Info");
    console.warn(companyVantageId);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  if (token && companyVantageId) {  

    const regExp = /[a-zA-Z]/g;
    const companyGroupUuid = regExp.test(companyVantageId) ? companyVantageId : await getCompanyGroupUuid(token, companyVantageId, "getTagIdResultsForCompanyGroup");
    
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getTagIdResultsForCompanyGroup requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      const companyGroupIdSource = companyGroupUuid;
      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwOsatResultsByTagForCompanyGroup}DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&CompanyGroupId_Source=eq.${companyGroupIdSource}&ContactRelationId=eq.${contactType}&order=DateStartedAt.desc`
      console.warn(endpointWithFilters);
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getTagIdResultsForCompanyGroup: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getTagIdResultsForCompanyGroup");
          console.log(results);
          console.log("apiOsatResult");
          console.log(apiOsatResult);
        }

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getTagIdResultsForCompanyGroup");
      return apiResult;
    }

    return apiResult;
  }
}

export const getAgenciesAssociatedByCompanyGroup = async(token, companyVantageId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getAgenciesAssociatedByCompanyGroup FUNCTION")
    console.log("getAgenciesAssociatedByCompanyGroup Token Info");
    console.log(token);
    console.log(companyVantageId);
  }

  if (token && companyVantageId) { 
    
    const regExp = /[a-zA-Z]/g;
    const companyGroupUuid = regExp.test(companyVantageId) ? companyVantageId : await getCompanyGroupUuid(token, companyVantageId, "getCompanyResultsByTagIdForCompanyGroup");
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Agp");
    myHeaders.append("Content-Profile", "Agp");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getAgenciesAssociatedByCompanyGroup requestOptions");
      console.log(requestOptions);
    }

    const endpointWithFilters = `${dwAgenciesBycompanyGroup}CompanyGroupId=eq.${companyGroupUuid}&HasActiveSubscription=eq.true&order=CompanyId.desc`    

    let apiResult = [];
    try{   
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getAgenciesAssociatedByCompanyGroup: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiContactRelationResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getAgenciesAssociatedByCompanyGroup");
          console.log(results);
          console.log("apiContactRelationResult");
          console.log(apiContactRelationResult);
        }

        return apiContactRelationResult
      }

    }catch(err){
      console.error("ERROR processing getAgenciesAssociatedByCompanyGroup");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCompanyGroupUuid = async(token, companyId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getCompanyGroupUuid");
    console.log("getCompanyGroupUuid Token Info");
    console.log(companyId);
    console.log(whoCalledMe);
  }

  if (token && companyId) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Agp");
    myHeaders.append("Content-Profile", "Agp");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanyGroupUuid requestOptions");
      console.log(requestOptions);
    }

    const endpointWithFilters = `${dwCompanyGroupUudi}CompanyId_Parent=eq.${companyId}`;      

    let apiResult = [];
    try{   
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanyGroupUuid: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        let apiContactRelationResult = await results.json();

        const associatedUuid = apiContactRelationResult[0]?.CompanyGroupId;

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanyGroupUuid");
          console.log(results);
          console.log("UUID");
          console.log(associatedUuid);
        }

        return associatedUuid;
      }

    }catch(err){
      console.error("ERROR processing getCompanyGroupUuid");
      return null;
    }

    return null;
  }
}

export const getParentCompanyGroupUuidFromCompanySyncId = async(token, companyId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getParentCompanyGroupUuidFromCompanySyncId");
    console.log("getParentCompanyGroupUuidFromCompanySyncId Token Info");
    console.log(companyId);
    console.log(whoCalledMe);
  }

  if (token && companyId) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Agp");
    myHeaders.append("Content-Profile", "Agp");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getParentCompanyGroupUuidFromCompanySyncId requestOptions");
      console.log(requestOptions);
    }
    const endpointWithFilters = `${dwParentCompanyGroupData}CompanyId=eq.${companyId}&CompanyGroupTypeId=eq.Corporation&CompanyId_Parent=neq.1618`;      

    let apiResult = [];
    try{   
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getParentCompanyGroupUuidFromCompanySyncId: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        let apiContactRelationResult = await results.json();

        const parentId = apiContactRelationResult[0]?.CompanyGroupId;

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getParentCompanyGroupUuidFromCompanySyncId");
          console.log(results);
          console.log("ParentId");
          console.log(parentId);
        }

        return parentId;
      }

    }catch(err){
      console.error("ERROR processing getParentCompanyGroupUuidFromCompanySyncId");
      return null;
    }

    return null;
  }
}

export const getContactRelationOptionsFilters = async(token, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getContactRelationOptionsFilters")
    console.log("getContactRelationOptionsFilters Token Info");
    console.log(token);
    console.log(whoCalledMe);
  }

  if (token) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Agp");
    myHeaders.append("Content-Profile", "Agp");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getContactRelationOptionsFilters requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      const results = await fetch(dwContactRelation, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getContactRelationOptionsFilters: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiContactRelationResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getContactRelationOptionsFilters");
          console.log(results);
          console.log("apiContactRelationResult");
          console.log(apiContactRelationResult);
        }

        return apiContactRelationResult
      }

    }catch(err){
      console.error("ERROR processing getContactRelationOptionsFilters");
      return apiResult;
    }

    return apiResult;
  }
}

export const getDateRangeOptionsFilters = async(token, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getDateRangeOptionsFilters")
    console.log("getDateRangeOptionsFilters Token Info");
    console.log(token);
    console.log(whoCalledMe);
  }

  if (token) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Agp");
    myHeaders.append("Content-Profile", "Agp");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getDateRangeOptionsFilters requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      const results = await fetch(dwDateRangeOptions, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getDateRangeOptionsFilters: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiDateRangeResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getDateRangeOptionsFilters");
          console.log(results);
          console.log("apiDateRangeResult");
          console.log(apiDateRangeResult);
        }

        return apiDateRangeResult
      }

    }catch(err){
      console.error("ERROR processing getDateRangeOptionsFilters");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCompanyGroupNpsAndOsatDataForBayadaCorp = async(token, startDate, endDate, dateType, contactId, companyVantageId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("^^^^Inside getCompanyGroupNpsAndOsatData")
    console.log("getCompanyGroupNpsAndOsatData Token Info");
    console.log(companyVantageId);
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  if (token && companyVantageId) {     
    const regExp = /[a-zA-Z]/g;
    const companyGroupUuid = regExp.test(companyVantageId) ? companyVantageId : await getCompanyGroupUuid(token, companyVantageId, "getCompanyGroupNpsAndOsatData");
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanyGroupNpsAndOsatData requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      const companyTargetGroup = 'c47b82c2-35c1-11eb-9010-4b3000001618';
      const companyGroupIdSource = companyGroupUuid; // e.i c47b82c2-35c1-11eb-9010-4b3000004187
      // default to quarter bayada      
      let  dateRangeType = (dateType && dateType === 'Quarter') ? 'Quarter_Bayada' : dateType; 
      dateRangeType = dateRangeType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      
      const endpointWithFilters = `${dwNpsAndOsatOverallCompanyGroupData}CompanyGroupId_Target=eq.${companyTargetGroup}&DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&CompanyGroupId_Source=eq.${companyGroupIdSource}&ContactRelationId=eq.${contactType}&order=DateStartedAt.desc`;        
      console.warn(endpointWithFilters);
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanyGroupNpsAndOsatData: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiCompanyNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanyGroupNpsAndOsatData");
          console.log(results);
          console.log("apiCompanyNpsAndOsatResult");
          console.log(apiCompanyNpsAndOsatResult);
        }
        console.log("==========END");
        return apiCompanyNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing getCompanyGroupNpsAndOsatData");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCompanyGroupSyncNpsAndOsatDataForCompanySync = async(token, startDate, endDate, dateType, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("^^^^Inside getCompanyGroupSyncNpsAndOsatDataForCompanySync")
    console.log("getCompanyGroupSyncNpsAndOsatDataForCompanySync Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  if (token) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanyGroupSyncNpsAndOsatDataForCompanySync requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      const companyTargetGroup = 'c47b82c2-35c1-11eb-9010-4b3000001618';
      // default to quarter bayada      
      let  dateRangeType = (dateType && dateType === 'Quarter') ? 'Quarter_Bayada' : dateType; 
      dateRangeType = dateRangeType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
            
      const endpointWithFilters = `${dwNpsCompanyGroupSyncForCompanySync}CompanyGroupId=eq.${companyTargetGroup}&DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=DateStartedAt.asc`;
      console.warn(endpointWithFilters);

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanyGroupSyncNpsAndOsatDataForCompanySync: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiCompanyGroupSyncNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanyGroupSyncNpsAndOsatDataForCompanySync");
          console.log(results);
          console.log("***getCompanyGroupSyncNpsAndOsatDataForCompanySync");
          console.warn(apiCompanyGroupSyncNpsAndOsatResult);
        }

        return apiCompanyGroupSyncNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing getCompanyGroupSyncNpsAndOsatDataForCompanySync");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCompanyGroupTagIdsResults = async(token, startDate, endDate, dateType, contactId, companyVantageId, groupId, whoCalledMe) => {
  
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getCompanyGroupTagIdsResults")
    console.log("getCompanyGroupTagIdsResults Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  if (token && companyVantageId) {  

    const regExp = /[a-zA-Z]/g;
    const companyGroupUuid = regExp.test(companyVantageId) ? companyVantageId : await getCompanyGroupUuid(token, companyVantageId, "getCompanyGroupTagIdsResults");

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanyGroupTagIdsResults requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids     
      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';

      const endpointWithFilters = `${dwTagResultsForCompanyGroup}CompanyGroupId=eq.${companyGroupUuid}&DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=DateStartedAt.desc`
      
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanyGroupTagIdsResults: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiResults = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanyGroupTagIdsResults");
          console.log(results);
          console.log("apiResults");
          console.log(apiResults);
        }

        return apiResults
      }

    }catch(err){
      console.error("ERROR processing getCompanyGroupTagIdsResults");
      return apiResult;
    }

    return apiResult;
  }
}

export const getNpsAndOsatDataForCompanyGroups = async(token, companyVantageId, startDate, endDate, dateType, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("^^^^Inside getNpsAndOsatDataForCompanyGroups")
    console.log("getNpsAndOsatDataForCompanyGroups Token Info");
    // console.log(token);
    console.log(startDate);
    console.log(endDate);
    console.log(dateType);
    console.log(contactId);
    console.log(whoCalledMe);
  }

  if (token && companyVantageId) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getNpsAndOsatDataForCompanyGroups requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{        
      const regExp = /[a-zA-Z]/g;
      const companyGroupUuid = regExp.test(companyVantageId) ? companyVantageId : await getCompanyGroupUuid(token, companyVantageId, "getCompanyResultsByTagIdForCompanyGroup");
      // default to quarter bayada      
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
            
      const endpointWithFilters = `${dwNpsCompanyGroupSyncForCompanySync}CompanyGroupId=eq.${companyGroupUuid}&DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=DateStartedAt.asc`;
      console.warn(endpointWithFilters);

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getNpsAndOsatDataForCompanyGroups: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiCompanyGroupNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getNpsAndOsatDataForCompanyGroups");
          console.log(results);
          console.log("***getNpsAndOsatDataForCompanyGroups");
          console.warn(apiCompanyGroupNpsAndOsatResult);
        }

        return apiCompanyGroupNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing getCompanyGroupNpsAndOsatDataForCompanyGroupNotCorps");
      return apiResult;
    }

    return apiResult;
  }
}

export const getNpsAndOsatDataForCompanyGroupsByUUID = async(token, companyGroupUuid, startDate, endDate, dateType, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("^^^^Inside getNpsAndOsatDataForCompanyGroupsByUUID")
    console.log("getNpsAndOsatDataForCompanyGroupsByUUID Token Info");
    // console.log(token);
    console.log(startDate);
    console.log(endDate);
    console.log(dateType);
    console.log(contactId);
    console.log(whoCalledMe);
  }

  if (token && companyGroupUuid) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getNpsAndOsatDataForCompanyGroupsByUUID requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{        
      // default to quarter bayada      
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
            
      const endpointWithFilters = `${dwNpsCompanyGroupSyncForCompanySyncAliases}CompanyGroupId=in.(${companyGroupUuid})&DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=DateStartedAt.asc`;
      console.warn(endpointWithFilters);

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getNpsAndOsatDataForCompanyGroupsByUUID: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiCompanyGroupNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getNpsAndOsatDataForCompanyGroupsByUUID");
          console.log(results);
          console.log("***getNpsAndOsatDataForCompanyGroupsByUUID");
          console.warn(apiCompanyGroupNpsAndOsatResult);
        }

        return apiCompanyGroupNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing getNpsAndOsatDataForCompanyGroupsByUUID");
      return apiResult;
    }

    return apiResult;
  }
}

export const getNpsAndOsatGoalsForCompanyGroupsByUUID = async(token, companyGroupUuid, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("^^^^Inside getNpsAndOsatGoalsForCompanyGroupsByUUID")
    console.log("getNpsAndOsatGoalsForCompanyGroupsByUUID Token Info");
  }

  if (token && companyGroupUuid) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getNpsAndOsatGoalsForCompanyGroupsByUUID requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{        
      const contactType = contactId ?? 'Client';
            
      const endpointWithFilters = `${dwNpsAndOsatCompanyGroupGoals}CompanyGroupId=in.(${companyGroupUuid})&ContactRelationId=eq.${contactType}`;
      console.warn(endpointWithFilters);

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getNpsAndOsatGoalsForCompanyGroupsByUUID: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiCompanyGroupNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getNpsAndOsatGoalsForCompanyGroupsByUUID");
          console.log(results);
          console.log("***getNpsAndOsatGoalsForCompanyGroupsByUUID");
          console.warn(apiCompanyGroupNpsAndOsatResult);
        }

        return apiCompanyGroupNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing getNpsAndOsatGoalsForCompanyGroupsByUUID");
      return apiResult;
    }

    return apiResult;
  }
}

export const postNpsAndOsatGoalsForCompanyGroupsByUUID = async(token, goals, companyGroupUuid, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("^^^^Inside postNpsAndOsatGoalsForCompanyGroupsByUUID")
    console.log("postNpsAndOsatGoalsForCompanyGroupsByUUID Token Info");
  }

  if (token && companyGroupUuid) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    myHeaders.append("Prefer", "resolution=merge-duplicates,return=representation");
    myHeaders.append("Content-Type", "application/json"); 
    
    goals.ContactRelationId = contactId ?? 'Client';

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
         goals
      }),
      redirect: 'follow',
    };


    if(env.ENVIROMENT !== "prod"){
      console.log("postNpsAndOsatGoalsForCompanyGroupsByUUID requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{        
      const endpointWithFilters = `${dwNpsAndOsatCompanyGroupGoalsPOST}`;
      console.warn(endpointWithFilters);

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in postNpsAndOsatGoalsForCompanyGroupsByUUID: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 201){
        const apiCompanyGroupNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->postNpsAndOsatGoalsForCompanyGroupsByUUID");
          console.log(results);
          console.log("***postNpsAndOsatGoalsForCompanyGroupsByUUID");
          console.warn(apiCompanyGroupNpsAndOsatResult);
        }

        return apiCompanyGroupNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing postNpsAndOsatGoalsForCompanyGroupsByUUID");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCompanyGroupSyncNpsAndOsatData = async(token, startDate, endDate, dateType, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("^^^^Inside getCompanyGroupSyncNpsAndOsatData")
    console.log("getCompanyGroupSyncNpsAndOsatData Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  if (token) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanyGroupSyncNpsAndOsatData requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      const companyTargetGroup = 'c47b82c2-35c1-11eb-9010-4b3000001618';
      // default to quarter bayada      
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      
      const endpointWithFilters = `${dwCompanyGroupSyncNpsAndOsat}CompanyGroupId_Target=eq.${companyTargetGroup}&DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=DateStartedAt.desc,CompanyGroupName_Source.asc`;

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanyGroupSyncNpsAndOsatData: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiCompanyNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanyGroupSyncNpsAndOsatData");
          console.log(results);
          console.log("apiCompanyNpsAndOsatResult");
          console.log(apiCompanyNpsAndOsatResult);
        }
        console.log("==========END");
        return apiCompanyNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing getCompanyGroupSyncNpsAndOsatData");
      return apiResult;
    }

    return apiResult;
  }
}

export const getIndustryNpsAndOsatData = async(token, startDate, endDate, dateType, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("getIndustryNpsAndOsatData Token Info");
    // console.log(token);
    console.log(startDate);
    console.log(endDate);
    console.log(dateType);
    console.log(contactId);
    console.log(contactId);
  }

  if (token) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getIndustryNpsAndOsatData requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      
      const endpointWithFilters = `${dwIndustryNpsAndOsat}DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=DateStartedAt.desc`
      
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Location Info in getIndustryNpsAndOsatData: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiIndustryNpsAndOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("apiIndustryNpsAndOsatResult");
          console.log(apiIndustryNpsAndOsatResult);
        }

        return apiIndustryNpsAndOsatResult
      }

    }catch(err){
      console.error("ERROR processing apiIndustryNpsAndOsatResult");
      return apiResult;
    }


  }
}

export const getCompanyOsatBrokendownDataByCompanyGroup = async(token, startDate, endDate, dateType, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getCompanyOsatBrokendownDataByCompanyGroup")
    console.log("getCompanyOsatBrokendownDataByCompanyGroup Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  if (token) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanyOsatBrokendownDataByCompanyGroup requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwOsatBrokendownDataByCompanyGroup}CompanyId=eq.5873&DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=DateStartedAt.desc,TagId.asc`;
      
      console.log(endpointWithFilters);

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanyOsatBrokendownDataByCompanyGroup: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanyOsatBrokendownDataByCompanyGroup");
          console.log(results);
          console.log("apiOsatResult");
          console.log(apiOsatResult);
        }

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getCompanyOsatBrokendownDataByCompanyGroup");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCompanyResultsByTagIdForCompanyGroupSync = async(token, startDate, endDate, dateType, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getCompanyResultsByTagIdForCompanyGroupSync")
    console.log("getCompanyResultsByTagIdForCompanyGroupSync Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  if (token) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCompanyResultsByTagIdForCompanyGroupSync requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwOsatResultsByTagForCompanyGroupSync}DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=CompanyGroupId_Source.desc,DateStartedAt.desc,TagId.asc`;

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCompanyResultsByTagIdForCompanyGroupSync: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCompanyResultsByTagIdForCompanyGroupSync");
          console.log(results);
          console.log("apiOsatResult");
          console.log(apiOsatResult);
        }

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getCompanyResultsByTagIdForCompanyGroupSync");
      return apiResult;
    }

    return apiResult;
  }
}

export const getOverallOsatCompanyResultsByCompanyGroupForCompanyGroupSync = async(token, startDate, endDate, dateType, contactId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.log("Inside getOverallOsatCompanyResultsByCompanyGroupForCompanyGroupSync")
    console.log("getOverallOsatCompanyResultsByCompanyGroupForCompanyGroupSync Token Info");
    // console.log(token);
    // console.log(startDate);
    // console.log(endDate);
    // console.log(dateType);
    // console.log(contactId);
    // console.log(whoCalledMe);
  }

  if (token) {  
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpAnalytics");
    myHeaders.append("Content-Profile", "AgpAnalytics");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getOverallOsatCompanyResultsByCompanyGroupForCompanyGroupSync requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      // get a list of range of types ids
      // How to get CompanyGroupId_Target
      // Get IndustryId
      // default to quarter bayada
      const dateRangeType = dateType ?? 'Quarter_Bayada';
      // Default to 6 months if no date is passed: HOW TO DEFAULT TO date saftely 
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const contactType = contactId ?? 'Client';
      const endpointWithFilters = `${dwOsatOverallResultsByIndustryForCompanyGroupSync}DateRangeTypeId=eq.${dateRangeType}&IndustryId=eq.HomeCare&DateStartedAt=gte.${dateStartedAt}&DateEndedBefore=lte.${dateEndedBefore}&ContactRelationId=eq.${contactType}&order=CompanyGroupId_Source.desc,DateStartedAt.desc`;
            
      console.log(endpointWithFilters);

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getOverallOsatCompanyResultsByCompanyGroupForCompanyGroupSync: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiOsatResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getOverallOsatCompanyResultsByCompanyGroupForCompanyGroupSync");
          console.log(results);
          console.log("apiOsatResult");
          console.log(apiOsatResult);
        }

        return apiOsatResult
      }

    }catch(err){
      console.error("ERROR processing getOverallOsatCompanyResultsByCompanyGroupForCompanyGroupSync");
      return apiResult;
    }

    return apiResult;
  }
}

export const getListOfClientsForCompany = async(token, companyVantageId, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.warn("Inside getListOfClientsForCompany");
    console.warn("getListOfClientsForCompany Token Info");
    // console.log(token);
    console.log(companyVantageId);
  }

  
  if (token && companyVantageId) {  

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "AgpSurvey");
    myHeaders.append("Content-Profile", "AgpSurvey");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getListOfClientsForCompany requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{   
      
      const contactType ='Client';
      const endpointWithFilters = `${dwReportSampleContactTermDetails}ContactRelationId=eq.${contactType}&CompanyId=in.(${companyVantageId})&order=ContactTermStartedAt.desc`;
      
      console.log("endpointWithFiltersTYRON");
      console.log(endpointWithFilters);

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getListOfClientsForCompany: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("defaultStartDate");
          console.log(defaultStartDate);
          console.log("resultsFor->getListOfClientsForCompany");
          console.log(results);
          console.warn("results---->>>getListOfClientsForCompany");
          console.log(apiResult);
        }
        

        return apiResult
      }

    }catch(err){
      console.error("ERROR processing getListOfClientsForCompany");
      return apiResult;
    }

    return apiResult;
  }
}

export const getCexReportDataForCompanyGroupSync = async(token, startDate, endDate, whoCalledMe) => {
  if(env.ENVIROMENT !== "prod"){
    console.warn("Inside getCexReportDataForCompanyGroupSync")
    console.warn("getCexReportDataForCompanyGroupSync Token Info");
    // console.log(token);
  }

  
  if (token) {  

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept-Profile", "Bayada");
    myHeaders.append("Content-Profile", "Bayada");
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    if(env.ENVIROMENT !== "prod"){
      console.log("getCexReportDataForCompanyGroupSync requestOptions");
      console.log(requestOptions);
    }

    let apiResult = [];
    try{         
      const dateStartedAt = startDate ?? defaultStartDate;
      const dateEndedBefore = endDate ?? defaultEndDate;
      const endpointWithFilters = `${dwCexReport}&StartedAt=gte.${dateStartedAt}&StartedAt=lte.${dateEndedBefore}&order=StartedAt.desc`;
       
      console.log("endpointWithFiltersCEX");
      console.log(endpointWithFilters);

      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving Info in getCexReportDataForCompanyGroupSync: from ${whoCalledMe}`);
        console.error(error)
        return apiResult;
      }); 

      if(results.status === 200){
        const apiResult = await results.json();

        if(env.ENVIROMENT !== "prod"){
          console.log("resultsFor->getCexReportDataForCompanyGroupSync");
          console.log(results);
          console.warn("results---->>>getCexReportDataForCompanyGroupSync");
          console.log(apiResult);
        }
        

        return apiResult
      }

    }catch(err){
      console.error("ERROR processing getCexReportDataForCompanyGroupSync");
      return apiResult;
    }

    return apiResult;
  }
}

export const getListOfClientsForCompanyFromVantage = async(token, companyVantageId, whoCalledMe) => {
  let _userAccount = [];
  if(env.ENVIROMENT !== "prod"){
    console.warn("Inside getListOfClientsForCompanyFromVantage");
    console.warn("getListOfClientsForCompanyFromVantage Token Info");
    console.log(companyVantageId);
  }

  if (token && companyVantageId > 0) {
    requestBody["JWT"] = token;
    requestBody["Username"] = "";
    requestBody["Email"] = "";
    requestBody["VantageCompanyId"] = companyVantageId;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: 'follow'
    };
    
    try{

      const endpointWithFilters = `${decisionsEndPoint}eaff9a7a-24bc-11ee-9280-326dddd3e106`;
        
      const results = await fetch(endpointWithFilters, requestOptions).catch((error) => {
        console.log(`Error Retrieving accountInfo: from ${whoCalledMe}`);
        console.error(error)
      });    
      const apiResult = await results.json();
      const parsedResult = apiResult["VantageClient"];
      console.log("++++apiResult");
      console.log(apiResult);
      console.log(parsedResult)
      return parsedResult.length > 0 ? parsedResult : _userAccount;

    }catch(err){
      return _userAccount;
    }
  }

  return _userAccount;
  
}

const AccountExperienceService = {
  postNpsAndOsatGoalsForCompanyGroupsByUUID,
  postCompanySyncNpsAndOsatGoals,
  getListOfClientsForCompanyFromVantage,
  getCompanyGroupSyncNpsAndOsatData,
  getIndustryNpsAndOsatData,
  getContactRelationOptionsFilters,
  getDateRangeOptionsFilters,
  getCompanyOsatBrokendownDataByCompanyGroup,
  getCompanyResultsByTagIdForCompanyGroupSync,
  getOverallOsatCompanyResultsByCompanyGroupForCompanyGroupSync,
  getCompanyGroupUuid,
  getAgenciesAssociatedByCompanyGroup,
  getCompanyGroupNpsAndOsatDataForBayadaCorp,
  getTagIdResultsForCompanyGroup,
  getOverallOsatCompanyResultsByCompanyGroupForCompanyGroup,
  getOverallOsatTagResultsByCompaniesUnderCompanyGroup,
  getCompanySyncNpsAndOsatData,  
  getCompanyResultsByTagIdForCompanySync,
  getCompanyGroupSyncNpsAndOsatDataForCompanySync,
  getCompanyGroupSyncResultsTagIds,
  getParentCompanyGroupUuidFromCompanySyncId,
  getAllQuestionsForGivenCompany,
  getAllQuestionsForCompaniesUnderGivenCompanyGroup,
  getBayadaExclusiveDateRanges,
  getNpsAndOsatDataForCompanyGroups,
  getCompanyGroupTagIdsResults,
  getListOfClientsForCompany,
  getCexReportDataForCompanyGroupSync,
  getAllQuestionsForCompaniesUnderGivenCompanyGroupWithDivisionsAndCorps,
  getNpsAndOsatDataForCompanyGroupsByUUID,
  getCompanySyncNpsAndOsatGoals,
  getNpsAndOsatGoalsForCompanyGroupsByUUID
};

export default AccountExperienceService;
