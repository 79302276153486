import {
	DISPLAY_MAIN_GENERAL_TOOGLE,
	MAIN_UPPER_TOOGLE_VALUE,
	ON_SHIPPING_TAB_CHANGE,
	ON_EDIT_ADDRESS_CHANGE,
	ON_EDIT_USER_PROFILE_CHANGE,
	ON_ADDRESS_REQUEST,
	ON_SELECTING_CORRECTION_MODAL,
	ON_SELECTING_CORRECTION_RECORD,
	ON_SELECTING_ROW_KEYS,
	ON_CHANGING_TABS_IN_INTERVIEWS_DASHBOARD,
	ON_DOWNLOADING_ROW_KEYS_TRANSFORM,
	ON_SELECTING_REPORT_TAB,
	ON_SELECTING_PRINTING_FOR_ALL_ROWS,
	ON_FILTERING_TABLE_RESULTS
} from '../constants/General';

import { env } from "../../configs/EnvironmentConfig";

const initState = {  
  isMainGeneralToogleVisible: true,
  isMainGeneralToogleValueOn: true,
  shippingTabKey: 'mailing',
  isToEditShippingAddress: false,
  isToEditUserProfile: false,
  isCorrectionModalOpened: false,
  selectedRowKeys: [],
  selectedRowRecords: [],
  isToProceedToPrintAllRecords: false
}

const general = (state = initState, action) => {
	switch (action.type) {
		case ON_FILTERING_TABLE_RESULTS:
			return {
				...state,
				filteredRowKeys: action.filteredRowKeys,
				filteredRows: action.filteredRows
			}
		case ON_SELECTING_PRINTING_FOR_ALL_ROWS:
			return {
				...state,
				isToProceedToPrintAllRecords: action.isToProceedToPrintAllRecords
			}
		case ON_SELECTING_REPORT_TAB:
			return{
				...state,
				selectedReportTabName: action.selectedReportTabName,
				fileNameWithDate: action.fileNameWithDate
			}
		case ON_DOWNLOADING_ROW_KEYS_TRANSFORM:
			return{
				...state,
				selectedRowRecordsInCsvPattern: action.selectedRowRecordsInCsvPattern
			}
		case ON_SELECTING_CORRECTION_MODAL:
			return {
			  ...state,        
			  isCorrectionModalOpened: action.isCorrectionModalOpened
			}
		case ON_SELECTING_CORRECTION_RECORD:
			return {
			  ...state,
			  selectedCorrectionRecord: action.selectedCorrectionRecord
			}
		case ON_ADDRESS_REQUEST:
			return {
			  ...state,
			  userAddresses: action.userAddresses,
			  tabIndexedAddresses: action.tabIndexedAddresses
			}
		case ON_EDIT_ADDRESS_CHANGE:    
		return {
		  ...state,
		  isToEditShippingAddress: action.isToEditShippingAddress
		}
		case ON_SHIPPING_TAB_CHANGE:
			return {
			  ...state,
			  shippingTabKey: action.shippingTabKey
			}
		case ON_EDIT_USER_PROFILE_CHANGE:
			return {
			  ...state,
			  isToEditUserProfile: action.isToEditUserProfile
			}		
		case DISPLAY_MAIN_GENERAL_TOOGLE:	
			return {
				...state,
				isMainGeneralToogleVisible : action.displayValue
			}
		case MAIN_UPPER_TOOGLE_VALUE:
			return {
				...state,
				isMainGeneralToogleValueOn : action.toogleValue
			}
	
		case ON_SELECTING_ROW_KEYS:		
			let keys = [];
			let records = [];
			if(action.isSingleRecordSelected){
				let mostRecentItemAdded = state.selectedRowKeys[0];
				if(action.selectedRowKey !== mostRecentItemAdded && !(state.selectedRowKeys?.includes(action.selectedRowKey))){
					keys = [action.selectedRowKey, ...state.selectedRowKeys];
					records = [action.selectedRowRecord, ...state.selectedRowRecords]
				}
				else if(action.selectedRowKey !== mostRecentItemAdded && (state.selectedRowKeys?.includes(action.selectedRowKey))){
					keys = [...state.selectedRowKeys];
					records = [...state.selectedRowRecords];

					let index = keys.findIndex((key) => key === action.selectedRowKey);
					let recordIndex = records.findIndex((record => record.key === action.selectedRowRecord?.key));

					keys.splice(index, 1); // 2nd parameter means remove one item only
					records.splice(recordIndex, 1)
				}
				else if(action.selectedRowKey === mostRecentItemAdded && (state.selectedRowKeys?.includes(action.selectedRowKey))){			
					keys = [...state.selectedRowKeys];
					records = [...state.selectedRowRecords];
					keys.shift();			
					records.shift();
				}else{
					keys = [...state.selectedRowKeys];
					records = [...state.selectedRowRecords];
				}
			}else{
				console.warn("OLD", state.selectedRowKeys?.length);
				console.warn("INCOMING", action.selectedRowKey?.length);
				if(action.selectedRowKey && (state.selectedRowKeys?.length !== action.selectedRowKey)){					
					keys = [... action?.selectedRowKey];
					records = [...action.selectedRowRecord];
				}
			}
			return {
			...state,
			selectedRowKeys: keys,
			selectedRowRecords: records,
			reportName: action.reportName
			}
		default:
			return state;
	}
}

export default general