import { APP_PREFIX_PATH } from '../AppConfig'
import { getCompanyGroupReportNestedSubmenu } from './CompanyGroupReportNestedSubmenu/CompanyGroupReportSubmenu';
import allowedSetGoalsForAgencies from 'assets/data/AllowedAgenciesGoalSetting.data.json';

export const getCompanyGroupSubNavWithProperConfiguration = (companyId, userName, groupId, companyGroupIds) => {
	
	const companyGroupSubNavigation = [
		{
			key: 'sidenav.experience.locations',
			path: `${APP_PREFIX_PATH}/experience/locations`,
			title: 'sidenav.experience.locations',
			icon: '',
			iconAlt:'/img/sidebar/Side-Nav-Location.svg',
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'sidenav.experience.reports',
			path: `${APP_PREFIX_PATH}/experience/reports`,
			title: 'sidenav.experience.reports',
			icon: '',
			iconAlt:'/img/sidebar/Side-Nav-Reports.svg',
			breadcrumb: false,
			submenu: [
				...getCompanyGroupReportNestedSubmenu(companyId, userName, groupId, companyGroupIds)
			]
		},
		{
			key: 'sidenav.experience.resources',
			path: `${APP_PREFIX_PATH}/experience/resources-corp`,
			title: 'sidenav.experience.resources',
			icon: '',
			iconAlt:'/img/sidebar/Side-Nav-Resources.svg',
			breadcrumb: false,
			submenu: []
		},
		{
			key: 'sidenav.experience.settings',
			path: `${APP_PREFIX_PATH}/experience/settings`,
			title: 'sidenav.experience.settings',
			icon: '',
			iconAlt: '/img/sidebar/Side-Nav-Settings.svg',
			breadcrumb: false,
			submenu: [
				{
					key: 'sidenav.experience.settings.contactInfoAndUsersCorp',
					path: `${APP_PREFIX_PATH}/experience/contact-info-and-users-corp`,
					title: 'sidenav.experience.settings.contactInfoAndUsersCorp',
					icon: '',
					iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
					breadcrumb: false,
					isRootMenuItem: false,				
					submenu: []
				},
				{
					key: 'sidenav.experience.settings.billing',
					path: `${APP_PREFIX_PATH}/experience/billing-corp`,
					title: 'sidenav.experience.settings.billing',
					icon: '',
					iconAlt:'/img/sidebar/Account-Chevron-Right.svg',
					breadcrumb: false,
					isRootMenuItem: false,				
					submenu: []
				}
			]
		}
	]
	
	if(isToAllowSettingGoals(companyId)){
		companyGroupSubNavigation.push({
			key: 'sidenav.experience.goals',
			path: `${APP_PREFIX_PATH}/experience/goal-setting`,
			title: 'sidenav.experience.goals',
			icon: '',
			iconAlt:'/img/sidebar/Side-Nav-Goal.svg',
			breadcrumb: false,
			isRootMenuItem: false,				
			submenu: []
		})
	}

	return companyGroupSubNavigation;
}


const isToAllowSettingGoals = (companyId) => {
	if(companyId){		
		const permissions = allowedSetGoalsForAgencies[0];		
		const agencyNumber = companyId ?? 0;		
		return (permissions?.allowedAgencies?.includes(agencyNumber)) ? true : false;
	}
	
	return false;
}