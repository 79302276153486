import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from '../../components/shared-components/Loading';
import { APP_PREFIX_PATH } from '../../configs/AppConfig'; // IntervieewsDashboard
import {MyClients, MyCaregivers, ReviewIntegrations, InformLetters, MonthlyReports, CustomPDF, NetPromoterDiscovery,
	    MyLeadInbox, MyServiceArea, MyListings, MyAwards, AwardCriteria, Notifications, YourLogo, BillingDefault, HelpCenter, Locations, Support, FileUploadCorp,
	    ReportReference, SatisfactionManagement, Comparison, Trends, BillingCorp, ContactInfoAndUsersCorp, GoToVantage, FileUpload, ActivatedInsightsGate, PinnacleRetain, PinnacleGate } from './experience/generalViews/index';
import { Users } from './experience/users/index'
import { env } from "../../configs/EnvironmentConfig";

export const AppViews = () => {

	function retry(fn, retriesLeft = 5, interval = 1000) {
		return new Promise((resolve, reject) => {
		  fn()
			.then(resolve)
			.catch((error) => {
			  setTimeout(() => {
				if (retriesLeft === 1) {
				  reject(error);
				  return;
				}
	  
				// Passing on "reject" is the important part
				retry(fn, retriesLeft - 1, interval).then(resolve, reject);
			  }, interval);
			});
		});
	  }  

	// Place any number of views before the Redirect
	// TODO: Make this list into a "map" dynamic && test experience path
	return (
		<>
	<Suspense fallback={<Loading cover="content"/>}>
		<Switch>
			<Route exact path={`${APP_PREFIX_PATH}/experience`} component={lazy(() => retry(() => import(`./experience`)))} />
			<Route path={`${APP_PREFIX_PATH}/experience-corp`} component={lazy(() => retry(() => import(`./experience/company-group-experience`)))} />
			<Route exact path={`${APP_PREFIX_PATH}/training`} component={lazy(() => import(`./training`))} />
			<Route exact path={`${APP_PREFIX_PATH}/account-selection`} component={lazy(() => retry(() => import(`./account-selection`)))} />

			<Route exact path={`${APP_PREFIX_PATH}/profile/billing`} component={lazy(() => retry(() => import(`./profile/billing`)))} />
			<Route exact path={`${APP_PREFIX_PATH}/profile/edit-profile`} component={lazy(() => retry(() => import(`./profile/edit-profile`)))} />
			

			<Route path={`${APP_PREFIX_PATH}/experience/cex-report`}  component={lazy(() => retry(() => import(`./experience/company-group-experience/cex-report`)))} />
			<Route path={`${APP_PREFIX_PATH}/experience/dnc-report`}  component={lazy(() => retry(() => import(`./experience/company-group-experience/dnc-report`)))} />
			<Route path={`${APP_PREFIX_PATH}/experience/goal-setting`}  component={lazy(() => retry(() => import(`./experience/company-group-experience/goal-setting`)))} />			
			<Route path={`${APP_PREFIX_PATH}/experience/billing-corp`} component={BillingCorp} />
			
			<Route path={`${APP_PREFIX_PATH}/experience/surveys`} component={lazy(() => retry(() => import(`./experience/surveys`)))} />			

			{env.IS_CORRECTIONS_TABLE_FEATURE_ON ? 				
				<Route path={`${APP_PREFIX_PATH}/experience/list-of-my-:relationType`} component={lazy(() => retry(() => import(`./experience/company-experience/contact-relationship`)))} />
				 :
				<Route path={`${APP_PREFIX_PATH}/experience/list-of-my-clients`} component={MyClients} />
			}

			{env.IS_CORRECTIONS_TABLE_FEATURE_ON ? 								
				<Route path={`${APP_PREFIX_PATH}/experience/list-of-my-:relationType`} component={lazy(() => retry(() => import(`./experience/company-experience/contact-relationship`)))} />
				 :
				<Route path={`${APP_PREFIX_PATH}/experience/list-of-my-caregivers`} component={MyCaregivers} />
			}
		
			<Route path={`${APP_PREFIX_PATH}/experience/review-integrations`} component={ReviewIntegrations} />
			
			<Route path={`${APP_PREFIX_PATH}/experience/resources`} component={lazy(() => retry(() => import(`./resources/resources-default`)))} />
			<Route path={`${APP_PREFIX_PATH}/experience/resources-corp`} component={lazy(() => retry(() => import(`./resources/resource-corp`)))} />

			<Route path={`${APP_PREFIX_PATH}/experience/inform-letters`} component={InformLetters} />
			<Route path={`${APP_PREFIX_PATH}/experience/monthly-reports`} component={MonthlyReports} />
			<Route path={`${APP_PREFIX_PATH}/experience/custom-pdf`} component={CustomPDF} />
			<Route path={`${APP_PREFIX_PATH}/experience/net-promoter-discovery`} component={NetPromoterDiscovery} />
			<Route path={`${APP_PREFIX_PATH}/experience/my-service-area`} component={MyServiceArea} />
			<Route path={`${APP_PREFIX_PATH}/experience/my-listing`} component={MyListings} />
			<Route path={`${APP_PREFIX_PATH}/experience/my-awards`} component={MyAwards} />
			<Route path={`${APP_PREFIX_PATH}/experience/award-criteria`} component={AwardCriteria} />
			<Route path={`${APP_PREFIX_PATH}/experience/notifications`} component={Notifications} />
			<Route path={`${APP_PREFIX_PATH}/experience/yourlogo`} component={YourLogo} />

			<Route path={`${APP_PREFIX_PATH}/experience/activated-insights`} component={ActivatedInsightsGate} />
			<Route path={`${APP_PREFIX_PATH}/experience/pinnacle-retain`} component={PinnacleRetain} />
			<Route path={`${APP_PREFIX_PATH}/experience/pinnacle-qi`} component={PinnacleGate} />
			<Route path={`${APP_PREFIX_PATH}/experience/pinnacle-cahps`} component={PinnacleGate} />


			{env.IS_NEW_USER_APP_OPTION_ON ? 
				<Route exact path={`${APP_PREFIX_PATH}/experience/users`} component={lazy(() => retry(() => import(`./experience/users/users-2.0`)))} />
				:				
				<Route path={`${APP_PREFIX_PATH}/experience/users`} component={lazy(() => retry(() => import(`./experience/users`)))} />
			}
					
			<Route path={`${APP_PREFIX_PATH}/experience/billing`} component={BillingDefault} />
			<Route path={`${APP_PREFIX_PATH}/experience/locations`} component={lazy(() => retry(() => import(`./experience/company-group-experience/locations`)))} />

			<Route path={`${APP_PREFIX_PATH}/experience/report-reference`} component={ReportReference} />
			<Route path={`${APP_PREFIX_PATH}/experience/satisfaction-management`} component={SatisfactionManagement} />
			<Route path={`${APP_PREFIX_PATH}/experience/comparison`} component={Comparison} />
			<Route path={`${APP_PREFIX_PATH}/experience/trends`} component={Trends} />			
			<Route path={`${APP_PREFIX_PATH}/experience/billing-corp`} component={BillingCorp} />
            <Route path={`${APP_PREFIX_PATH}/experience/contact-info-and-users-corp`} component={ContactInfoAndUsersCorp} />			
			<Route path={`${APP_PREFIX_PATH}/go-to-vantage`} component={GoToVantage} />
			<Route path={`${APP_PREFIX_PATH}/help-center`} component={HelpCenter} />
			<Route path={`${APP_PREFIX_PATH}/support`} component={Support} />

			<Route exact path={`${APP_PREFIX_PATH}/reputation`} component={lazy(() => import(`./reputation`))} />
			<Route exact path={`${APP_PREFIX_PATH}/benchmarking`} component={lazy(() => import(`./benchmarking`))} />		
			<Route exact path={`${APP_PREFIX_PATH}/logout`} component={lazy(() => import(`./logout`))} />
			<Route exact path={`${APP_PREFIX_PATH}/file-upload-provider`} component={FileUpload} />
			<Route exact path={`${APP_PREFIX_PATH}/file-upload-corp`} component={FileUploadCorp} />

			<Redirect from={`${APP_PREFIX_PATH}/switch-account`} to={`${APP_PREFIX_PATH}/account-selection`} />
			<Redirect from={`${APP_PREFIX_PATH}/logout`} to={`${APP_PREFIX_PATH}/experience`} />					
			<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/experience`} />
			<Redirect from={`${APP_PREFIX_PATH}/`} to={`${APP_PREFIX_PATH}/experience`} />							
		</Switch>
	</Suspense>
	</>
	)
}

export default React.memo(AppViews);