import UserService from "../../services/UserService";
import ProfileService from "../../services/ProfileService";
import {
	MAIN_UPPER_TOOGLE_VALUE,
	DISPLAY_MAIN_GENERAL_TOOGLE,
  ON_SHIPPING_TAB_CHANGE,
  ON_EDIT_ADDRESS_CHANGE,
  ON_EDIT_USER_PROFILE_CHANGE,
  ON_ADDRESS_REQUEST,
  ON_SELECTING_CORRECTION_RECORD,
  ON_SELECTING_CORRECTION_MODAL,
  ON_SELECTING_ROW_KEYS,
  ON_CHANGING_TABS_IN_INTERVIEWS_DASHBOARD,
  ON_DOWNLOADING_ROW_KEYS_TRANSFORM,
  ON_SELECTING_REPORT_TAB,
  ON_SELECTING_PRINTING_FOR_ALL_ROWS,
  ON_FILTERING_TABLE_RESULTS
} from '../constants/General';

export const onAddressRequestLoad = async() => {
  const selectedCompany = await UserService.getSelectedAgency(); 
  const rawUserAddresses =  await ProfileService.getCompanyAddressesByCompanyId(UserService.getToken(), selectedCompany?.CompanyId, "onAddressRequestLoad");
  const addressesTabIndexes = await ProfileService.formCompanyAddressTypesByIdForTabIndexing(rawUserAddresses);

  return {
    type:ON_ADDRESS_REQUEST,
    userAddresses: rawUserAddresses[0]?.companyAddresses,
    tabIndexedAddresses: addressesTabIndexes
  }
}

export const onNewExperienceToogleValueChange = (toogleValue) => {
  return {
    type: MAIN_UPPER_TOOGLE_VALUE,
    toogleValue:toogleValue
  };
};


export const onNewExperienceToogleVisibilityChange = (displayValue) => {
  return {
    type: DISPLAY_MAIN_GENERAL_TOOGLE,
    displayValue:displayValue
  };
};

export const onShippingKeyTabChange = async(key) => {
  return {
    type: ON_SHIPPING_TAB_CHANGE,
    shippingTabKey: key
  }
};

export const onIsToEditShippingAddressChange = async(value) => {
  return {
    type: ON_EDIT_ADDRESS_CHANGE,
    isToEditShippingAddress: value
  }
} 

export const onIsToEditUserProfileChange = async(value) => {
  return {
    type:ON_EDIT_USER_PROFILE_CHANGE,
    isToEditUserProfile: value 
  }
}

export const onCorrectionsModalChange = async(isCorrectionModalOpened) => {
  return {
    type: ON_SELECTING_CORRECTION_MODAL,
    isCorrectionModalOpened: isCorrectionModalOpened
  }
}

export const onSelectingCorrectionToEdit = async(record) => {
  return {
    type: ON_SELECTING_CORRECTION_RECORD,
    selectedCorrectionRecord: record
  }
}

export const onSelectingRowKeys = async(rowKeys, isSingleRecordSelected, selectedRowRecord, tableName) => {
  return {
    type: ON_SELECTING_ROW_KEYS,
    selectedRowKey: rowKeys,
    isSingleRecordSelected: isSingleRecordSelected,
    selectedRowRecord: selectedRowRecord,
    reportName: tableName
  }
}

export const onFilteringTableResults = async(filteredRowKeys, filteredRows) => {
  return {
    type: ON_FILTERING_TABLE_RESULTS,
    filteredRowKeys: filteredRowKeys,
    filteredRows: filteredRows
  }
}

export const onDownloadingRowKeysTransform = async(selectedRowRecordsInCsvPattern) => {
  return {
    type: ON_DOWNLOADING_ROW_KEYS_TRANSFORM,
    selectedRowRecordsInCsvPattern: selectedRowRecordsInCsvPattern
  }
}

export const onSelectingReportTab = async(reportTabName, fileNameWithDate ) => {
  return {
    type: ON_SELECTING_REPORT_TAB,
    selectedReportTabName: reportTabName,
    fileNameWithDate: fileNameWithDate
  }
}

export const onSelectingPrintingForAllRows = async(isToProceed) => {
  return {
    type: ON_SELECTING_PRINTING_FOR_ALL_ROWS,
    isToProceedToPrintAllRecords: isToProceed
  }
}
