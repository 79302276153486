import { act } from 'react-dom/test-utils';
import {
	ON_COMPANY_LOCATIONS_REQUEST,
	ON_SELECTING_COMPANY_FROM_LOCATION,
	ON_SELECTING_LOCATION_VIEW_ID,
	ON_REQUESTING_REPORT_FOR_LOCATION_FROM_DROPDOWN,
	ON_CONTACT_CORRECTIONS_REQUEST,
	ON_LOADING_LOCATIONS_VIEW,
	ON_COMPANY_SELECTION_FROM_LOCATIONS_LIST,
	ON_LOADING_ANY_XM_PAGE_REQUEST,
	ON_REQUESTING_GRAPH_FOR_LANDING_DASHBOARD,
	ON_REQUESTING_DATA_FOR_COMPANY_GROUP_SYNC_LANDING_DASHBOARD,
	ON_REQUESTING_FILTERS_FOR_LANDING_DASHBOARD,	
	ON_SELECTING_DATE_FREQUENCY_IN_LANDING_DASHBOARD,
	ON_SELECTING_RELATION_TYPE_IN_LANDING_DASHBOARD,
	ON_SELECTING_MORE_INFO_ON_PERCENTAGE_DASHBOARD,
	ON_REQUESTING_INFORMATION_FOR_COMPANY_GROUP,
	ON_REQUESTING_DATA_FOR_COMPANY_GROUP_LANDING_DASHBOARD,
	ON_REQUESTING_DATA_FOR_COMPANY_SYNC_LANDING_DASHBOARD,
	ON_REQUESTING_DATE_RANGE_FOR_LANDING_DASHBOARD,
	ON_SEARCHING_FOR_COMPANIES,
	ON_REQUESTING_DATE_CUSTOM_CONFIGURATION,
	ON_SELECTING_DATE_RANGE_FROM_DATE_PICKER,
	ON_TESTING_REDUX,
	ON_REQUESTING_SURVEY_RESPONSES_FOR_COMPANY_GROUP_SYNC,
	ON_REQUESTING_QUICKSIGHT_REPORTS_FOR_USER,
	ON_CHANGING_CURRENT_LOCATION,
	ON_REQUESTING_MOUNTING_QUICKSIGHT_EMBEDDABLE_URL,
	ON_AWS_QS_URL_MOUNTED,
	ON_SETTING_FILTERED_COMPANY_GROUP_OPTIONS,
	ON_SELECTING_COMPANY_FROM_LOCATIONS_LIST,
	ON_REQUESTING_CLIENT_REPORT_DASHBOARD_FOR_COMPANY,
	ON_SETTING_ACTIVE_TAB_IN_ANY_NATIVE_DASHBOARD,
	ON_SETTING_ACTIVE_TAB_FOR_SURVEYS_DASHBOARDS,
	ON_REQUESTING_CEX_REPORT_FOR_COMPANY_GROUP_SYNC,
	ON_SELECTING_DOWNLOAD_TYPE_IN_LANDING_DASHBOARD,
	ON_REQUESTING_DNC_REPORT_FOR_COMPANY_GROUP_SYNC,
	ON_SETTING_NPS_OSAT_TABLE,	
	ON_SUBMITTING_GOAL_ACTION,
	ON_SELECTING_RECORD_TO_EDIT,
	ON_SELECTING_RECORD_KEY_TO_EDIT
} from '../constants/Experience';
import * as companyConst from 'constants/CompanyConstant';

const initState = {	
	isFetching: false,
	quicksightFetchedDashboards: [],
	quicksightEmbeddedConfiguration: [],
	isAwsQsUrlAlreadyEmbedded: false,
	pocActiveTab: companyConst.NPS,
	surveysActiveTab: companyConst.SURVEYS
};

const experience = (state = initState, action) => {
	switch (action.type) {
		case ON_REQUESTING_DNC_REPORT_FOR_COMPANY_GROUP_SYNC:
			return {
				...state,
				preparedDncResponses: action.preparedDncResponses
			}
		case ON_SELECTING_DOWNLOAD_TYPE_IN_LANDING_DASHBOARD:
			return {
				...state,
				selectedDownloadType: action.selectedDownloadType
			}
		case ON_SETTING_ACTIVE_TAB_FOR_SURVEYS_DASHBOARDS:
			return {
				...state,
				surveysActiveTab: action.surveysActiveTab
			}
		case ON_SETTING_ACTIVE_TAB_IN_ANY_NATIVE_DASHBOARD:
			return {
				...state,
				pocActiveTab: action.pocActiveTab

			}
		case ON_SUBMITTING_GOAL_ACTION:			
			return {
				...state,
				modifiedActiveCompanyWithCompanyGroupList: action.updatedGoalRecordTableObjectData,
				lastEditedRecordKey: action.selectedRecordKey
			}
		case ON_SELECTING_RECORD_TO_EDIT:			
			return {
				...state,
				selectedRecord: action.selectedRecord
			}
		case ON_SELECTING_RECORD_KEY_TO_EDIT:
			return{
				...state,
				selectedEditingRecordKey: action.selectedEditingRecordKey
			}
		case ON_REQUESTING_CLIENT_REPORT_DASHBOARD_FOR_COMPANY:
			return {
				...state,
				clientsReportConfiguration: action.clientsReportConfiguration
			}
		case ON_SELECTING_COMPANY_FROM_LOCATIONS_LIST:
			return {
				...state,
				selectedAgencyFromLocationsList: action.selectedAgencyFromLocationsList
			}
		case ON_AWS_QS_URL_MOUNTED:
			return {
				...state,
				isAwsQsUrlAlreadyEmbedded: action.isAwsQsUrlAlreadyEmbedded
			}
		case ON_REQUESTING_MOUNTING_QUICKSIGHT_EMBEDDABLE_URL:
			let currentEmbeddedDashboardObject = [];
			let incomingEmbeddedDashboardObject = [];
			let combinedAlreadyEmbeddedDashboardObjects = [];

			currentEmbeddedDashboardObject = state.quicksightEmbeddedConfiguration;

			const itCurrentlyExists = currentEmbeddedDashboardObject?.some(obj => obj?.embeddedLocation === action.quicksightEmbeddedConfiguration?.embeddedLocation);

			if (!itCurrentlyExists) {
				incomingEmbeddedDashboardObject.push(action.quicksightEmbeddedConfiguration);
			}

			combinedAlreadyEmbeddedDashboardObjects = currentEmbeddedDashboardObject.concat(incomingEmbeddedDashboardObject);

			return {
				...state,
				quicksightEmbeddedConfiguration: combinedAlreadyEmbeddedDashboardObjects
			}
		case ON_REQUESTING_QUICKSIGHT_REPORTS_FOR_USER:
			// if state.quicksight
			let currentDashboardObjects = [];
			let incomingDashboardObject = [];
			let combinedDashboardObjects = [];

			// If object exists then dont add it
			const exists = incomingDashboardObject?.some(obj => obj?.embeddingLocation === action.quicksight?.embeddingLocation);
			if (!exists) {
				incomingDashboardObject.push(action.quicksight);
			}

			currentDashboardObjects = state.quicksightFetchedDashboards;
			combinedDashboardObjects = currentDashboardObjects.concat(incomingDashboardObject);
			return {
				...state,
				quicksight: action.quicksight,
				quicksightFetchedDashboards: combinedDashboardObjects
			}
		case ON_CHANGING_CURRENT_LOCATION:
			return {
				...state,
				currentLocation: action.currentLocation
			}
		case ON_TESTING_REDUX:
			return {
				...state,
				reduxTest: action.reduxTest
			}
		case ON_SELECTING_DATE_RANGE_FROM_DATE_PICKER:
			return {
				...state,
				unformattedStartSelectedDate: action.unformattedStartSelectedDate,
				unformattedEndSelectedDate: action.unformattedEndSelectedDate
			}
		case ON_REQUESTING_DATE_CUSTOM_CONFIGURATION:
			return {
				...state,
				customDateRanges: action.customDateRanges
			}
		case ON_SEARCHING_FOR_COMPANIES:
			return {
				...state,
				filteredAgencySearchList: action.filteredAgencySearchList,
				searchTerm: action.searchTerm
			}
		case ON_REQUESTING_DATE_RANGE_FOR_LANDING_DASHBOARD:
			return {
				...state,
				startSelectedDate: action.startSelectedDate,
				endSelectedDate: action.endSelectedDate,
			}
		case ON_REQUESTING_INFORMATION_FOR_COMPANY_GROUP:
			return {
				...state,
				companyGroupUUID: action.companyGroupUUID
			}
		case ON_SELECTING_MORE_INFO_ON_PERCENTAGE_DASHBOARD:
			return {
				...state,
				selectedInnerGraphIndex: action.selectedInnerGraphIndex,
				isInnerGraphIndexSelected: action.isInnerGraphIndexSelected,
				isToDisplayPercentageInnerGraph: action.isToDisplayPercentageInnerGraph
			}
		case ON_SELECTING_RELATION_TYPE_IN_LANDING_DASHBOARD:
			return {
				...state,
				relationType: action.relationType
			}
		case ON_SELECTING_DATE_FREQUENCY_IN_LANDING_DASHBOARD:
			return {
				...state,
				selectedDateType: action.selectedDateType
			}
		case ON_REQUESTING_DATA_FOR_COMPANY_GROUP_SYNC_LANDING_DASHBOARD:
			return {
				...state,
				landingCompanyGroupSyncDashboardData: action.landingCompanyGroupSyncDashboardData,
				industryDashboardData: action.industryDashboardData,
				landingOsatResultsByTagIdForCompanyGroupSync: action.landingOsatResultsByTagIdForCompanyGroupSync,
				landingOsatTotalResultsByIndustryForCompanyGroupSync: action.landingOsatTotalResultsByIndustryForCompanyGroupSync,
				companyGroupSyncOsatNpsGoals: action.companyGroupSyncOsatNpsGoals,
				startSelectedDate: action.startSelectedDate,
				endSelectedDate: action.endSelectedDate
			}
		case ON_REQUESTING_DATA_FOR_COMPANY_GROUP_LANDING_DASHBOARD:
			return {
				...state,
				landingCompanyGroupDashboardData: action.landingCompanyGroupDashboardData,
				industryDashboardData: action.industryDashboardData,
				landingOsatResultsByTagIdForCompanyGroup: action.landingOsatResultsByTagIdForCompanyGroup,
				landingOsatTotalResultsByIndustryForCompanyGroup: action.landingOsatTotalResultsByIndustryForCompanyGroup,
				landingOsatCompanyResultsByTagId: action.landingOsatCompanyResultsByTagId,
				companyGroupOsatNpsGoals: action.companyGroupOsatNpsGoals,
				startSelectedDate: action.startSelectedDate,
				endSelectedDate: action.endSelectedDate

			}
		case ON_REQUESTING_DATA_FOR_COMPANY_SYNC_LANDING_DASHBOARD:
			return {
				...state,
				landingCompanySyncDashboardData: action.landingCompanySyncDashboardData,
				landingCompanyGroupDashboardData: action.landingCompanyGroupDashboardData,
				landingCompanyGroupSyncDashboardData: action.landingCompanyGroupSyncDashboardData,
				industryDashboardData: action.industryDashboardData,
				companySyncOsatNpsGoals: action.companySyncOsatNpsGoals,
				landingOsatResultsByTagIdForCompanySync: action.landingOsatResultsByTagIdForCompanySync,
				landingOsatTagResultsForCompanyGroupSyncAndIndustry: action.landingOsatTagResultsForCompanyGroupSyncAndIndustry,
				landingOsatTagResultsForCompanyGroup: action.landingOsatTagResultsForCompanyGroup,
				allSurveyResponsesConfiguration: action.allSurveyResponsesConfiguration,
				startSelectedDate: action.startSelectedDate,
				endSelectedDate: action.endSelectedDate,
				preparedSurveyResponses: action.preparedSurveyResponses,
			}
		case ON_REQUESTING_SURVEY_RESPONSES_FOR_COMPANY_GROUP_SYNC:
			return {
				...state,
				preparedSurveyResponses: action.preparedSurveyResponses,
				startSelectedDate: action.startSelectedDate,
				endSelectedDate: action.endSelectedDate,
			}
		case ON_REQUESTING_FILTERS_FOR_LANDING_DASHBOARD:
			return {
				...state,
				contactRelationOptions: action.contactRelationOptions,
				dateRangeOptions: action.dateRangeOptions
			}
		case ON_REQUESTING_GRAPH_FOR_LANDING_DASHBOARD:
			return {
				...state,
				graphData: action.graphData
			}
		case ON_CONTACT_CORRECTIONS_REQUEST:
			return {
				...state,
				correctionsByContactType: action.correctionsByContactType
			}
		case ON_COMPANY_LOCATIONS_REQUEST:
			let incomingSearchableList = [];
			incomingSearchableList = action.searchableLocationsList

			return {
				...state,
				agencyLocationsList: action.agencyLocationsList,
				searchableLocationsList: incomingSearchableList,
				unmodifiedSearchableLocationsList: incomingSearchableList,
				activeCompanyWithCompanyGroupList: action.activeCompanyWithCompanyGroupList
				
			}
		case ON_SETTING_NPS_OSAT_TABLE:
			return {
				...state,
				activeCompanyWithCompanyGroupList: action.activeCompanyWithCompanyGroupList				
			}
		case ON_SETTING_FILTERED_COMPANY_GROUP_OPTIONS:
			return {
				...state,
				searchableLocationsList: action.companyGroupFilteredOptions

			}
		case ON_SELECTING_COMPANY_FROM_LOCATION:
			return {
				...state,
				selectedCompanyNameFromLocationView: action.selectedCompanyNameFromLocationView,
				selectedCompanyKeyFromLocationView: action.selectedCompanyKeyFromLocationView,
				selectedCompanyPathFromLocationView: action.selectedCompanyPathFromLocationView
			}
		case ON_SELECTING_LOCATION_VIEW_ID:
			return {
				...state,
				selectedDashboardId: action.selectedDashboardId
			}
		case ON_REQUESTING_REPORT_FOR_LOCATION_FROM_DROPDOWN:
			return {
				...state,
				selectedLocationCompanyId: action.selectedLocationCompanyId,
				selectedLocationGroupId: action.selectedLocationGroupId,
				isSelectedACompanyGroup: action.isSelectedACompanyGroup
			}
		case ON_LOADING_LOCATIONS_VIEW:
			return {
				...state,
				lighterActiveLocationsList: action.lighterActiveLocationsList
			}
		case ON_COMPANY_SELECTION_FROM_LOCATIONS_LIST:
			return {
				...state,
				isLocationSelected: action.isLocationSelected
			}
		case ON_LOADING_ANY_XM_PAGE_REQUEST:
			return {
				...state,
				configurationDisplaySettingsPerPage: action.configurationDisplaySettingsPerPage
			}
		case ON_REQUESTING_CEX_REPORT_FOR_COMPANY_GROUP_SYNC:
			return {
				...state,
				preparedCexResponses: action.preparedCexResponses
			}
		default:
			return state;
	}
}

export default experience