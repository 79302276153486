import axios from 'axios';
import { env } from '../configs/EnvironmentConfig'

const loggingUrldEndPoint= `${env.DECISIONS_API_ENDPOINT_URL}/Primary/restapi/Flow/f2ce3bd0-62b6-11ec-91f8-326dddd3e106`;
// const loggingUrldEndPoint= `https://dev.app.homecarepulse.com/Primary/restapi/Flow/f2ce3bd0-62b6-11ec-91f8-326dddd3e106`;


const requestBody = {
  "userid": env.GUESS_ENDPOINT_USER_ID,
  "password": env.GUESS_PWD,
  "outputtype": "RawJson",
};

const areNeededFieldsDefined = (featureValue, featureUsed, featureLocation) => {
  if(typeof featureValue === 'undefined') {
      console.log('Variable "featureValue" is undefined.');
      return false;
  } else if(featureValue === null){
      console.log('Variable "featureValue" is null.');
      return false;
  }

  if(typeof featureUsed === 'undefined') {
      console.log('Variable "featureUsed" is undefined.');
      return false;
  } else if(featureUsed === null){
      console.log('Variable "featureUsed" is null.');
      return false;
  }

  if(typeof featureLocation === 'undefined') {
      console.log('Variable "featureLocation" is undefined.');
      return false;
  } else if(featureLocation === null){
      console.log('Variable "featureLocation" is null.');
      return false;
  }

  return true;
}

const wasRecordLoggedSuccesfully = (parsedResults) => {
  if(typeof parsedResults?.CipFeatureValueLogId === 'undefined') {
    console.log('Variable "featureUsed" is undefined.');
    return false;
  } else if(parsedResults?.CipFeatureValueLogId === null){
      console.log('Variable "featureUsed" is null.');
      return false;
  }

  return true;
}

export const logFeatureInteraction = async(token, companyId, featureUsed, featureValue, featureLocation) => {
  let succesfullyInserted = false;
  if ((token) && (companyId || companyId > 0) && (areNeededFieldsDefined(featureValue, featureUsed, featureLocation))) { 
    requestBody["JWT"] = token;  
    requestBody["CipFeatureValueLog"] = {
      "CompanyId": companyId,
      "FeatureUsed": featureUsed,
      "FeatureValue": featureValue, 
      "FeatureLocation": featureLocation,
    }  

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: 'follow'
    };
    
    try{
      const results = await fetch(loggingUrldEndPoint, requestOptions).catch((error) => {
        const whoCalledMe = "logFeatureInteraction()";
        console.log(`Error inserting Feature Data: from ${whoCalledMe}`);
        console.error(error)
      });    
      const apiResult = await results.json();
      const parsedResults  = apiResult["CipFeatureValueLog"];
      return wasRecordLoggedSuccesfully(parsedResults);

    }catch(err){      
      return succesfullyInserted;
    }
  }  
  return succesfullyInserted;
}


const FeatureLoggingService = {
  logFeatureInteraction
};

export default FeatureLoggingService;
