import { APP_PREFIX_PATH } from './AppConfig'
import {GoogleSVG, ExperienceSVG, TrainingSVG, ReviewManagerSVG} from '../assets/svg/icon'
import {getGlobalExperienceDashBoardSubmenu} from '../configs/ExperienceSubmenus/GlobalNavigation/GlobalNavigationExperienceSubMenu';

const dashBoardNavTree = [
	{
		key: 'experience',
		path: `${APP_PREFIX_PATH}/experience`,
		title: 'Experience',
		sideTitle: 'Experience Management',
		icon: ExperienceSVG,
		iconAlt:'/img/mainnav/Experience.svg',
		color: '#3CA292',
		current: true,
		isRootMenuItem: true,
		iconPosition: "upperNav",
		isServiceAvailableForUser: false,
		isSubmenuCorpType: false,
		globalSubmenu:[
			...getGlobalExperienceDashBoardSubmenu()
		],
		submenu: []
	},
	{
		key: 'training',
		path: `${APP_PREFIX_PATH}/training`,
		title: 'Training',
		sideTitle: 'Training',
		icon: TrainingSVG,
		iconAlt:'/img/mainnav/Training.svg',
		color: '#C4745F',
		current: false,
		isRootMenuItem: true,
		iconPosition: "upperNav",
		isServiceAvailableForUser: false,
		isSubmenuCorpType: false,
		globalSubmenu:[],
		submenu: []
	},
	{
		key: 'reputation',
		path: `${APP_PREFIX_PATH}/reputation`,
		title: 'Reputation',
		sideTitle: 'Reputation',
		icon: ReviewManagerSVG,
		iconAlt:'/img/mainnav/ReviewManager.svg',
		color: '#C89736',
		current: false,
		isRootMenuItem: true,
		iconPosition: "upperNav",
		isServiceAvailableForUser: false,
		isSubmenuCorpType: false,
		globalSubmenu:[],
		submenu: []
	}
]

const mainNavigationConfig = [
  ...dashBoardNavTree
]

export default mainNavigationConfig;
