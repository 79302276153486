import React, {Component} from 'react'
import {connect} from 'react-redux';
import {setUserPageClickedOnFlag}  from '../../../../redux/actions/Cip';
import { bindActionCreators } from 'redux';
import InternalIFrame from '../../../../components/layout-components/InternalIFrame';
import { env } from '../../../../configs/EnvironmentConfig';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

class Users extends Component {

    componentDidUpdate() {
        console.log("Users page loaded UseEffect");
            this.props.setUserPageClickedOnFlag(true);            
            if(this.props.isUsersPageClicked){                
                // this.props.history.push('/cip/experience/users');
                // this.props.setUserPageClickedOnFlag(false);
                // TODO: reset flag for reload much more smoothly maybe with proper redirect cause the one above does not process it well
                window.location.reload();                
            }
        }

        render(){  
              
            return(
                <div><InternalIFrame iFrameUrl={`${env.VANTAGE_URI}/v3/account/users?keep_printable=true`}/></div>
            )
    }
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({
        setUserPageClickedOnFlag: setUserPageClickedOnFlag
	}, dispatch)
}

const mapStateToProps = ({cip}) => {
	const {isUsersPageClicked} = cip ;
	return {isUsersPageClicked} 
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);