import React , { useEffect } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { APP_PREFIX_PATH } from '../../../configs/AppConfig';
import { env } from '../../../configs/EnvironmentConfig';
import utils from '../../../utils';
import { logFeatureUserInteraction  } from '../../../redux/actions/Cip';
import { connect } from "react-redux";

export const WidgetAction = (props) => {
	const { logFeatureUserInteraction, isCollapsed } = props;
	const openOldExperience = () => {
		window.open(`${env.VANTAGE_URI}?keep_printable=false`,"_blank");		
	}


	if(!isCollapsed){
		return (
			<div className="widget-action">		
				<p className="help-center-wrapper">			
				<Link to={`${APP_PREFIX_PATH}/help-center`}>
					<img className="help-center" src="/img/others/Side-Nave-Life-Ring-Light.svg" alt="" />Help Center
				</Link>					
				</p>
				<Link onClick={()=> openOldExperience()}>
					<Button>Back To Old Experience</Button>
				</Link>			
			</div>
		)
	}else{
		return (
			<div className="widget-action">		
				<p className="help-center-wrapper">			
				<Link to={`${APP_PREFIX_PATH}/help-center`}>
					<img className="help-center" src="/img/others/Side-Nave-Life-Ring-Light.svg" alt="" />
				</Link>					
				</p>
				<Link onClick={()=> openOldExperience()}>
					<img className="help-center" src="/img/others/angle-double-left-solid.svg" alt="" />
				</Link>			
			</div>
		)
	}

}

// export default WidgetAction;
const mapStateToProps = ({ cip }) => {
	const {logFeatureUserInteraction } = cip;
	return { logFeatureUserInteraction }
};

export default connect(mapStateToProps, {logFeatureUserInteraction})(WidgetAction);
