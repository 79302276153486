import axios from 'axios';
import { env } from '../configs/EnvironmentConfig';
const reviewManagerEndpoint = `${env.DECISIONS_API_ENDPOINT_URL}/Primary/restapi/Flow/2e0cf0c9-ee1f-11eb-91d1-326dddd3e106`;
const reviewmanagerByCompanyId = `${env.DECISIONS_API_ENDPOINT_URL}/Primary/H/?FlowId=483b460e-3036-11ec-91e8-326dddd3e106&&userid=PD-CIP-GUEST-0000&password=aC4PSbw6KBpUUy0tiuhe0cNR!&ForceFormat=true&`;
const vantageReviewManagerURL = `${env.VANTAGE_URI}/v3/reviewmanager?keep_printable=true`;
const rmSAML = `https://rm.homecarepulse.com/saml/login/5bd725cc949e5532bfa4d423`;
const oldSAML = `https://rm.reviewtrackers.com/saml/login/5bd725cc949e5532bfa4d423`;
const rmRT = `https://sso.homecarepulse.com/auth/realms/hcp/protocol/saml/clients/rm`
let requestBody = {
  "userid": env.GUESS_ENDPOINT_USER_ID,
  "password": env.GUESS_PWD,
  "outputtype": "RawJson"
};

export const isAccountSubscribedToReviewManager = async(CompanyId, token, username) => {
  let isUserSubscribed = false;
  
  if(CompanyId && token){
    requestBody["CompanyId"] = CompanyId;
    requestBody["Token"] = token;
     
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: 'follow'
    };
  
    try{
        const results = await fetch(reviewManagerEndpoint, requestOptions).catch((error) => {
          console.log(`Error Retrieving RM info: from fetch call`);
          console.error(error)
        }); 

      const apiResult = await results.json();
      const parsedResult = await apiResult["isSubscribed"];
    
      return parsedResult ? parsedResult : isUserSubscribed;
    }catch(err){
      return isUserSubscribed;
    }
  }
  return isUserSubscribed;
}

export const launchReviewManagerService = async(whoCalledMe) => {
  let accounts = {};
  // const response = await axios.get(keycloakUserAPI, {withCredentials: true}).catch((error) => {
  //   console.log(`Error Retrieving userInfo: ${whoCalledMe}`);
  //   console.error(error)
  // });
  // return response.data;
  return accounts;
}

export const redirectUserTabtoVantageReviewManager = async(companyId, isHcpAdmin, token) => { 
  console.log(companyId);
  setTimeout(
    function() {
      window.open(rmRT);
    }, 2000);
}; 

export const getVantageReviewManagerURL = () => {
  return vantageReviewManagerURL;
}

const ReviewManagerService = {
  isAccountSubscribedToReviewManager,
  launchReviewManagerService,
  redirectUserTabtoVantageReviewManager
};

export default ReviewManagerService;
